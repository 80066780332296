import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { Editor } from "primereact/editor";
import APIServices from "../../../service/APIService";
import { DateTime } from "luxon";
import { StickyContainer, Sticky } from 'react-sticky';

import { InputNumber } from "primereact/inputnumber";
import { AttachmentComponent } from "../../../components/Attachment";
import { getReportingFiscalYearByReportingperiod, getRPTextFormat } from "../../../components/BGHF/helper";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { BlockUI } from 'primereact/blockui';
import { ReadMoreComponent } from "../../../components/Forms/ReadMoreComponent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { hardcoded } from "../../constants/hardcodedid";
import LCA from "../../admin/TVS/SupplierScreen/LCA";


window.jQuery = $;
window.$ = $;

const SRFSubmission = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail)

    const [structuredResponse, setStructuredResponse] = useState([])
    const [data, setData] = useState({})
    const dpnamerefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
    const [tempload, setTempLoad] = useState(false)
    const [remarksdialog, setRemarksDialog] = useState(false)
    const [remarksdata, setRemarksData] = useState([])
    const [assignment, setAssignment] = useState([])
    const [pastdata, setPastData] = useState([])
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [assigneedialog, setAssigneeDialog] = useState(false)
    const [assigneeobj, setAssigneeObj] = useState({ reviewer_ids: [], reporter_ids: [], approver_ids: [] })

    const [response, setResponse] = useState({})

    const [document, setDocument] = useState(null)
    const [efcatass, setEFCatAss] = useState([])
    const [actual, setActual] = useState([])
    const [submisisonStatus, setSubmissionStatus] = useState(false)
    const [show, setShow] = useState(false)
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();

    const { id, params, submitId } = JSON.parse(sessionStorage.getItem('srfsubmission'))

    const userList = useSelector(state => state.userlist.supplierList)

    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196','245']
    useEffect(async () => {


        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        const promise0 = APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise1 = APIServices.get(API.SRF_Edit(id))
        Promise.all([promise0, promise1]).then((values) => {
            console.log(values)
            let locData = { ...values[1].data }

            const shapedSite = values[0].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            if (submitId) {
                APIServices.get(API.ValueChainSubmission_Edit(submitId)).then((res2) => {

                    setResponse(res2.data)
                    locData.data1 = res2.data.response
                    setData(locData)

                })
            } else {

                locData.data1 = JSON.parse(locData.data1)
                setData(locData)

            }
            setSiteList(shapedSite)
        })
    }, [login_data]);

    const checkUpdation = () => {
        let a = JSON.stringify(data.data1)
        let b = actual
        console.log(a === b)
        return a === b

    }





    const reviewAction = async (srfId, _id, index1, index2, year, rp_name) => {

        let ref = JSON.parse(JSON.stringify(pastdata))


        let dt = DateTime.utc()

        setTempLoad(true)
        Swal.fire({
            title: "Review  " + rp_name,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Quick Review",
            confirmButtonColor: '#5B8FF7',
            denyButtonText: `Check & Review`,
            denyButtonColor: 'green'
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const { value: accept } = await Swal.fire({

                    html: `<div >Do you wish to review this without opening the detailed submission ?</div>`,

                    confirmButtonColor: 'green',
                    showCancelButton: true,

                    confirmButtonText:
                        'Yes, Review',

                })

                if (accept) {
                    let newObj = { type: 2, reject: 0, reviewer_modified_on: DateTime.utc(), reviewer_modified_by: login_data.id, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
                    APIServices.patch(API.QN_Submission_Edit(_id), newObj).then(async (res) => {
                        try {
                            Object.values(ref[index1].dp_array[index2]).forEach(i => {
                                for (let j = 0; j < i.length; i++) {
                                    i[0].status = 4
                                    i[0].type = 2

                                }
                            }
                            )


                            setPastData(ref)

                            setTempLoad(false)
                        } catch (e) {
                            console.log(e)
                            setTempLoad(true)
                        }
                    }).catch((e) => {
                        setTempLoad(false)
                    })
                } else {
                    setTempLoad(false)
                }

            } else if (result.isDenied) {
                setTempLoad(false)
                window.open(
                    window.location
                        .origin +
                    "/data_input_reviewer/" +
                    srfId +
                    "/" +
                    _id
                );
            } else {
                setTempLoad(false)
            }
        });


    }
    const recallReview = async (srfId, _id, index1, index2, year) => {
        let ref = JSON.parse(JSON.stringify(pastdata))


        setTempLoad(true)
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Review</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this review</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Revoke submission',

        })
        if (accept) {
            let newObj = { type: 1, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
            APIServices.patch(API.QN_Submission_Edit(_id), newObj).then(async (res) => {
                try {
                    Object.values(ref[index1].dp_array[index2]).forEach(i => {
                        for (let j = 0; j < i.length; i++) {
                            i[0].status = 3
                            i[0].type = 1

                        }
                    }
                    )


                    setPastData(ref)
                    forceUpdate()
                    setTempLoad(false)
                } catch (e) {
                    console.log(e)
                    setTempLoad(true)
                }
            }).catch((e) => {
                setTempLoad(false)
            })
        } else {
            setTempLoad(false)
        }

    }
    const recall = async (srfId, _id, index1, index2, year) => {
        let ref = JSON.parse(JSON.stringify(pastdata))


        setTempLoad(true)

        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Review</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this review</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Revoke submission',

        })
        if (accept) {
            let newObj = { type: 0, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
            APIServices.patch(API.QN_Submission_Edit(_id), newObj).then(async (res) => {
                try {
                    Object.values(ref[index1].dp_array[index2]).forEach(i => {
                        for (let j = 0; j < i.length; i++) {
                            i[0].status = 0
                            i[0].type = 0

                        }
                    }
                    )
                    setPastData(ref);
                    forceUpdate()
                    setTempLoad(false)

                } catch (e) {
                    setTempLoad(true)
                }
            }).catch((e) => {
                setTempLoad(false)
            })
        } else {
            setTempLoad(false)
        }

    }
    const getDisplayStatus = (rp) => {
        console.log(rp)
        const [startMonth, endMonth] = rp.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days <= 0;

    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }

    function getMinMaxDates(dateStrings) {
        // Parse date strings and sort them
        const dates = dateStrings.map(dateStr => DateTime.fromFormat(dateStr, 'MM-yyyy')).sort((a, b) => a - b);

        // Get min and max dates
        const minDate = dates[0].startOf('month').toJSDate();
        const maxDate = dates[dates.length - 1].endOf('month').toJSDate();

        return {
            minDate,
            maxDate
        };
    }
    const actionTemplate = (rowData, rowindex, formindex) => {


        return (
            <>

                <Button
                    icon="pi pi-trash"
                    className="mr-2 actionbtn" style={{
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        color: 'palevioletred'
                    }}
                    onClick={() => {

                        if (data.data1[formindex].required === true) {

                            if (data.data1[formindex].data.length > 1) {
                                deleteRow(rowData, rowindex, formindex)
                            } else {
                                Swal.fire({
                                    position: "center",
                                    icon: "warning",
                                    title: "Mandatory to have atleast 1 entry",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }

                        } else {
                            deleteRow(rowData, rowindex, formindex)
                        }

                    }}
                />
            </>
        )
    }
    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const getEfCategoryList = (stdid, catid) => {
        let data = efcatass.filter((i) => { return i.efStandardId === stdid && i.efCategoryId === catid })
        if (data.length) {
            return (data[0].selected_ids === undefined || data[0].selected_ids === null) ? [] : data[0].selected_ids
        } else {
            return []
        }
    }
    const onCellEditComplete = (e, dataind) => {
        let { rowData, newValue, cellIndex, field, rowIndex, originalEvent: event } = e;

        let loc = JSON.parse(JSON.stringify(data))
        setTimeout(() => {
            let item = getObjectAtIndex(loc.data1[dataind].data[rowIndex], cellIndex)
            item.data['value'] = rowData[field];
            loc.data1[dataind].data[rowIndex][loc.data1[dataind].value[cellIndex]].data['value'] = rowData[field];
            delete loc.data1[dataind].data[rowIndex][field]
            setData(loc)
            console.log(loc)
            forceUpdate()
        }, 100)

    };
    const addRow = (data_, index) => {

        let loc = JSON.parse(JSON.stringify(data))
        loc.data1[index].data.push(data_[0])
        setData(loc)
        forceUpdate()
    }
    const deleteRow = (rowData, rowindex, formindex) => {
        let loc = JSON.parse(JSON.stringify(data))
        loc.data1[formindex].data.splice(rowindex, 1)
        setData(loc)
        forceUpdate()
    }
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const getObjectAtIndex = (data, index) => {
        const keys = Object.keys(data);
        const key = keys[index];
        return data[key];
    };
    const renderEditor = (options) => {
        let item = getObjectAtIndex(options.rowData, parseInt(options.field.split('_')[1]))

        switch (item.type) {

            case 1:
                return (<InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 2:
                return (<InputTextarea value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 3:
                return (<InputText type="number" value={options.value} onChange={(e) => options.editorCallback(parseFloat(e.target.value))} />)
            case 4:

                return (<Dropdown optionLabel="label" optionValue="value" value={options.value} options={item.data.values} onChange={(e) => options.editorCallback(e.value)} />)
            case 6:

                return (<Calendar dateFormat="dd/mm/yy" value={options.value} onChange={(e) => options.editorCallback(e.value)} />)

            case 5:
                return null
        }
    }
    const renderTableData = (rowData) => {

        switch (rowData.type) {
            case 5:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.label}</div>);
            case 1:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 2:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 3:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 4:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{(rowData.data.value === null || rowData.data.value === undefined) ? 'Select option' : rowData.data.values.find((i) => { return i.value === rowData.data.value }).label}</div>);
            case 6:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}> {(rowData.data.value === undefined || rowData.data.value == null) ? 'click here' : formatDate(rowData.data.value, 'dd-MM-yyyy')}</div>);
        }

    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <Calendar placeholder={item.placeholder} className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputNumber min={0} placeholder={item.placeholder} maxFractionDigits={(item.fraction === undefined || item.fraction) ? 0 : item.fraction < 0 ? 0 : item.fraction} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-18 fw-5 text-justify fw-7" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>} </label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}

                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <Editor className="text-area col-5" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-12" style={{
                        border: '1px solid #6366F1',
                        borderRadius: '10px'
                    }}>
                        <div style={{
                            background: '#6366F110',

                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} style={{
                                marginRight: 10,
                                padding: '5px',
                                fontSize: '15px',
                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',
                                color: 'cornflowerblue'
                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value != null && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: '300px',
                                overflow: 'scroll'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 10
                                                }} >
                                                    <div className="flex align-items-center" style={{ width: '50%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '25%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        } else if (item.type === 'table') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <DataTable className="fullheight" style={{ width: '100%' }} value={item.data}  >


                        {item.value.map((h) => {

                            return <Column bodyClassName={(a) => { return a[h].type === 5 ? 'p-disabled' : '' }} header={h} body={(rowData) => { return renderTableData(rowData[h]); }} editor={(options) => { return renderEditor(options) }} onCellEditComplete={(e) => { onCellEditComplete(e, index) }} />;
                        })}


                    </DataTable>

                </div>
            )
        }
        else if (item.type === 'tableadd') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="flex justify-content-end" style={{ margin: 10, width: '100%' }}>
                        {item.data.length < item.maxrowlimit && <Button onClick={() => { addRow(item.newrow, index) }} icon='pi pi-plus'></Button>}
                    </div>
                    <DataTable scrollable showGridlines className="fullheight" style={{ width: '100%', maxHeight: 300 }} value={item.data}  >


                        {item.value.map((h) => {

                            return <Column bodyClassName={(a) => { return a[h].type === 5 ? 'p-disabled' : '' }} header={h} body={(rowData) => { return renderTableData(rowData[h]); }} editor={(options) => { return renderEditor(options) }} onCellEditComplete={(e) => { onCellEditComplete(e, index) }} />;
                        })}
                        <Column header='Action' style={{ width: 50 }} body={(rowData, e) => { return actionTemplate(rowData, e.rowIndex, index) }} />

                    </DataTable>

                </div>
            )
        }
        else if (item.type === 'checkpoint') {
            console.log(item)
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}

                        > help</i></span>} </label>
                    <div className="col-5" >
                        <div className="grid">
                            {item.values.map((i) => {
                                return <label onClick={() => { item.value = i.value; forceUpdate() }} className="col-2 mr-2" style={{ justifyContent: 'center', borderRadius: 5, display: 'flex', width: 110, color: item.value === i.value ? 'white' : 'black', background: item.value === i.value ? item.value === '1' ? 'green' : item.value === '2' ? 'red' : 'orange' : 'white', border: '1px solid darkgray' }}>{i.label}</label>
                            })}

                        </div>

                    </div>

                </div>
            )
        } else if (item.type === 'htmleditor') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <div dangerouslySetInnerHTML={{ __html: item.label }} />
                    </div>
                    {/* <Editor value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} /> */}


                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        let existingValue = item.value ? item.value.reduce((a, b) => { return a + b.size }, 0) : 0
        if (allowedext.includes(ext)) {
            const file = e.target.files[0]
            if ((file.size + existingValue) <= 60000000 && file.size <= 20000000) {
                let formData = new FormData()
                formData.append('file', e.target.files[0])
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'

                    }
                }).then((res) => {
                    res.data.files[0].extension = ext
                    console.log(item)
                    if (!(item.value != null)) {
                        item['value'] = [...res.data.files]
                    } else {

                        item['value'].push(...res.data.files)


                    }
                    forceUpdate()
                }).catch((res) => {

                })
            }
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
        e.target.value = "";
    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }
    function checkEditorValue(htmlString) {
        if (!htmlString) {
            return true
        }
        const regex = /^<p>\s*<\/p>$/;
        return regex.test(htmlString);
    }
    const checkForm = (data) => {
        let total = 0, count = 0
        data.forEach((item) => {
            if (item.type === 'text' && item.required) {
                total += 1
                if (item.value != null && item.value.trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'textarea' && item.required) {
                total += 1
                if (item.value != null && item.value.trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required) {
                total += 1
                console.log(item.value)
                if (item.value !== undefined && !isNaN(item.value) && item.value.toString().trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        count += 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        count += 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        delete item.error
                    }
                }
            }
            else if (item.type === 'date' && item.required) {
                console.log(typeof item.value)
                total += 1
                if (item.value != null && (typeof item.value === 'object' || typeof item.value === 'string')) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'radio-group' && item.required) {
                total += 1
                if (item.value != null && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'checkbox-group' && item.required) {
                total += 1
                if (item.value != null && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'select' && item.required) {
                total += 1
                if (item.value != null) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'checkpoint' && item.required) {
                total += 1
                if (item.value != null) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'file' && item.required) {
                total += 1
                if (item.value != null && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'table' || item.type === 'tableadd') {
                if (item.type === 'tableadd' && item.required && item.data.length === 0) {
                    total -= total
                    item.error = 1
                } else {
                    delete item.error
                }

                Object.values(item.data).forEach((i) => {

                    Object.values(i).forEach((j) => {

                        if (j.type === 1 && j.data.required) {
                            total += 1
                            if (j.data.value != null && j.data.value.trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }
                        } else if (j.type === 2 && j.data.required) {
                            total += 1
                            if (j.data.value != null && j.data.value.trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 3 && j.data.required) {
                            total += 1
                            if (j.data.value != null && !isNaN(j.data.value) && j.data.value.toString().trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 4 && j.data.required) {
                            total += 1
                            if (j.data.value != null) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 6 && j.data.required) {

                            total += 1
                            if (j.data.value != null && (typeof item.value === 'object' || typeof item.value === 'string')) {
                                count += 1
                                delete j.data.error
                            } else {
                                j.data.error = 1
                            }

                        }


                    })
                })
            }
        })
        forceUpdate()

        return total === count && total !== 0
    }
    const checkFormForDisable = (data) => {
        let total = 0, count = 0
        JSON.parse(JSON.stringify(data)).forEach((item) => {
            if (item.type === 'text' && item.required) {
                total += 1
                if (item.value != null && item.value.trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'textarea' && item.required) {
                total += 1
                if (item.value != null && item.value.trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required) {
                total += 1
                console.log(item.value)
                if (item.value !== undefined && !isNaN(item.value) && item.value.toString().trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        count += 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        count += 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        delete item.error
                    }
                }
            }
            else if (item.type === 'date' && item.required) {
                console.log(typeof item.value)
                total += 1
                if (item.value != null && (typeof item.value === 'object' || typeof item.value === 'string')) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'radio-group' && item.required) {
                total += 1
                if (item.value != null && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'checkbox-group' && item.required) {
                total += 1
                if (item.value != null && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'select' && item.required) {
                total += 1
                if (item.value != null) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'checkpoint' && item.required) {
                total += 1
                if (item.value != null) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'file' && item.required) {
                total += 1
                if (item.value != null && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'table' || item.type === 'tableadd') {
                if (item.type === 'tableadd' && item.required && item.data.length === 0) {
                    total -= total
                    item.error = 1
                } else {
                    delete item.error
                }

                Object.values(item.data).forEach((i) => {

                    Object.values(i).forEach((j) => {

                        if (j.type === 1 && j.data.required) {
                            total += 1
                            if (j.data.value != null && j.data.value.trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }
                        } else if (j.type === 2 && j.data.required) {
                            total += 1
                            if (j.data.value != null && j.data.value.trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 3 && j.data.required) {
                            total += 1
                            if (j.data.value != null && !isNaN(j.data.value) && j.data.value.toString().trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 4 && j.data.required) {
                            total += 1
                            if (j.data.value != null) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 6 && j.data.required) {

                            total += 1
                            if (j.data.value != null && (typeof item.value === 'object' || typeof item.value === 'string')) {
                                count += 1
                                delete j.data.error
                            } else {
                                j.data.error = 1
                            }

                        }


                    })
                })
            }
        })


        return total === count && total !== 0
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }

    const saveSRF = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkForm(data.data1)) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            if (response.id) {
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id
                newObj['response'] = data.data1
                newObj['reject'] = 0
                newObj['edit'] = 0
                newObj['type'] = params.state.self === true ? 2 : 1

                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true
                })



                if (isConfirmed) {

                    if (typeof return_remarks === 'string' && return_remarks.trim()) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]

                    }

                    APIServices.patch(API.ValueChainSubmission_Edit(response.id), newObj).then((res) => {

                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }
            } else {
                newObj['srfId'] = data.id
                newObj['locationId'] = params.state.data.locationId
                newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
                newObj['level'] = params.state.data.level
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['response'] = data.data1
                newObj['self'] = params.state.data.self
                newObj['form_type'] = 1
                newObj['reject'] = 0
                newObj['edit'] = 0
                newObj['partnerType'] = login_data.role === 'clientsupplier' ? 1 : login_data.role === 'clientdealer' ? 2 : 3
                newObj['type'] = params.state.data.self === true ? 2 : 1
                newObj['entityUserAssId'] = params.state.data.entityUserAssId
                newObj['entityAssId'] = params.state.data.entityAssId
                newObj['frequency'] = params.state.data.frequency
                newObj['supplierId'] = params.state.data.supplierId
                newObj['vendorId'] = params.state.data.vendorId
                newObj['vendorCode'] = params.state.data.vendorCode
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = dt
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id

                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true
                })



                if (isConfirmed) {

                    if (typeof return_remarks === 'string' && return_remarks.trim()) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]

                    }

                    APIServices.post(API.ValueChainSubmission_UP(login_data.clientId), newObj).then((res) => {

                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }
            }

        }
        forceUpdate()

    }

    const getStructuredResponse = (daTa) => {
        let loc = JSON.parse(JSON.stringify(daTa)).filter(x => x.type !== 'paragraph' && x.name)
        let result = []
        for (const element of loc) {
            if (element.type === 'radio-group' || element.type === 'checkbox-group') {
                result.push({
                    maskId: element.name, uniqueId: element.name, uom: element.dpunit, userId: login_data.id, userProfileId: admin_data.id, isManualForm: true, valueType: 'array', value: element?.values?.filter(x => x.selected).map(x => x.label), title: element.label, label: element.label?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\n/g, " ")
                        ?.replace(/&nbsp;/g, " ")
                        ?.replace("&amp;", "&") || null, formType: 1, dataType: 1
                })
            } else {
                result.push({
                    maskId: element.name, uniqueId: element.name, uom: element.dpunit, userId: login_data.id, userProfileId: admin_data.id, isManualForm: true, valueType: element.value === null ? null : typeof element.value, value: element?.value, title: element.label, label: element.label?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\n/g, " ")
                        ?.replace(/&nbsp;/g, " ")
                        ?.replace("&amp;", "&") || null, formType: 1, dataType: 1
                })

            }
        }
        return result
    }
    const draftSRF = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (true) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            if (response.id) {
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id
                newObj['response'] = data.data1
                newObj['type'] = 0


                APIServices.patch(API.ValueChainSubmission_Edit(response.id), newObj).then((res) => {

                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })


            } else {
                newObj['srfId'] = data.id
                newObj['locationId'] = params.state.data.locationId
                newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
                newObj['level'] = params.state.data.level
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['response'] = data.data1
                newObj['self'] = params.state.data.self
                newObj['form_type'] = 1

                newObj['partnerType'] = login_data.role === 'clientsupplier' ? 1 : login_data.role === 'clientdealer' ? 2 : 3
                newObj['type'] = 0
                newObj['entityUserAssId'] = params.state.data.entityUserAssId
                newObj['entityAssId'] = params.state.data.entityAssId
                newObj['frequency'] = params.state.data.frequency
                newObj['supplierId'] = params.state.data.supplierId
                newObj['vendorId'] = params.state.data.vendorId
                newObj['vendorCode'] = params.state.data.vendorCode
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = dt
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id
                console.log(newObj)

                APIServices.post(API.ValueChainSubmission_UP(login_data.clientId), newObj).then((res) => {

                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })


            }

        }
        forceUpdate()

    }

    const emptySRF = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Please confirm that you have no submission to make for this reporting period. Please enter comments, if any. </div>`,
            input: 'textarea',
            inputValue: '',
            confirmButtonText: 'Yes, I have nothing to report',
            cancelButtonText: 'No, open the form again',
            allowOutsideClick: false,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value.trim()) {
                    return 'remarks mandatory'
                }
            }
        })

        if (return_remarks) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            let dt = DateTime.utc()
            if (response.id) {
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id
                newObj['response'] = []
                newObj['type'] = 0
                newObj['edit'] = 1
                newObj['reject'] = 0

                APIServices.patch(API.ValueChainSubmission_Edit(response.id), newObj).then((res) => {

                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })
            } else {
                newObj['srfId'] = data.id
                newObj['locationId'] = params.state.data.locationId
                newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
                newObj['level'] = params.state.data.level
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['response'] = []
                newObj['self'] = params.state.data.self
                newObj['form_type'] = 1

                newObj['partnerType'] = login_data.role === 'clientsupplier' ? 1 : login_data.role === 'clientdealer' ? 2 : 3
                newObj['type'] = params.state.data.self === true ? 2 : 1
                newObj['edit'] = 1
                newObj['reject'] = 0
                newObj['entityUserAssId'] = params.state.data.entityUserAssId
                newObj['entityAssId'] = params.state.data.entityAssId
                newObj['frequency'] = params.state.data.frequency
                newObj['supplierId'] = params.state.data.supplierId
                newObj['vendorId'] = params.state.data.vendorId
                newObj['vendorCode'] = params.state.data.vendorCode
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = dt
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id
                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]

                APIServices.post(API.ValueChainSubmission_UP(login_data.clientId), newObj).then((res) => {

                    Swal.fire({
                        title: "Data submitted as null report",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })
            }


        }
    }

    const getUser = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }

    const checkHardcoded = () => {

        return hardcoded.srf.includes(data.id)

    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = ''
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return text
    }
    const closeForm = async () => {
        Swal.fire({
            title: "Are you sure you want to close this screen? Any unsaved changes will be lost.",
            showDenyButton: true,
            confirmButtonText: "Close Form",
            confirmButtonColor: '#FA8072',
            denyButtonText: `No stay here`,
            denyButtonColor: '#9FE2BF'
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                window.close()
            } else {
                Swal.close()
            }
        })

    }
    return (
        <div className="col-12 font-lato" style={{ margin: 10 }}>
            <StickyContainer>
                <div className="col-12">
                    {(login_data.id !== undefined) ?
                        <div >
                            <Sticky topOffset={0}>
                                {({ style, isSticky }) => (<div style={{ ...style, top: 98, background: isSticky && 'white', zIndex: 100 }}>
                                    <div className="fs-20 col-12 grid m-0  fw-4  clr-gray-900 flex align-items-center" style={{ padding: isSticky ? 15 : 0 }}>
                                        <div className="col-11 text-justify" >Reporting of data pertaining to <span className="fw-7 clr-navy">{data.title}</span>  &nbsp; <small style={{ color: 'grey' }}>(SRF {data.id})</small>   </div>
                                        <div className="col-1 flex align-items-center justify-content-end" > <i onClick={() => { closeForm() }} className="pi pi-times close-icon cur-pointer" /> </div>
                                    </div>
                                    {/* paragraph="" */}

                                    <div className="readmore" hidden={isSticky ? true : false}> To enter <strong>{getCoverageText(params.state, sitelist)}</strong> data for the period <strong>{params.state.data.reporting_period}</strong>, kindly fill all necessary fields.When finished, click "Save and Submit" to complete, or "Save as Draft" to continue updating later.
                                    </div>
                                    {/* <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }} >
                                        <div className="grid justify-content-between col-12">
                                            <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>         <span className="clr-gray-3 fw-4">Reporting period:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.data.reporting_period}   </span>  </div>
                                            <div className="col-5 fs-16 " style={{ flexDirection: 'row', textAlign: 'end' }}>          <span className="clr-gray-3 fw-4">Coverage:&nbsp;</span> <span className="clr-gray-900 fw-7"> {getCoverageText(params.state, sitelist)} </span>  </div>

                                          
                                        </div>

                                    </div> */}
                                </div>)}
                            </Sticky>

                            {(data?.data1?.length !== 0 && !checkHardcoded()) ?
                                <div>
                                    <div className="bg-white" style={{ borderRadius: 4 }}>

                                        <div className="p-2">

                                            {data?.data1?.map((item, index) => {

                                                return renderItems(item, index)
                                            })

                                            }
                                            <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                            <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>


                                                {(!response.id || (response.id && response.type === 0)) && <Button label='Save as Draft' onClick={() => { draftSRF() }} className={"ml-4"}  ></Button>}
                                                {((!response.id && getDisplayStatus(params.state.data.reporting_period)) || (response.id && response.type === 0 && getDisplayStatus(getRPTextFormat(response.reporting_period)))) && <Button label='Save and Submit' disabled={!checkFormForDisable(data?.data1 || [])} onClick={() => { saveSRF() }} className="ml-4" ></Button>}

                                            </div>


                                        </div>

                                    </div>


                                </div>
                                :
                                checkHardcoded() ?
                                    <div>
                                        <div className="bg-white" style={{ borderRadius: 4 }}>

                                            <div className="p-2">
                                                <div>{
                                                    data?.id === 86 &&
                                                    <LCA getData={(e) => { data.data1 = e; }} edit={1} data={(e) => data.data1 = e} getStatus={(e) => { setSubmissionStatus(false) }} />
                                                }
                                                </div>

                                                <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>


                                                    {(!response.id || (response.id && response.type === 0)) && <Button label='Save as Draft' onClick={() => { draftSRF() }} className={"ml-4"}  ></Button>}
                                                    {((!response.id && getDisplayStatus(params.state.data.reporting_period)) || (response.id && response.type === 0 && getDisplayStatus(getRPTextFormat(response.reporting_period)))) && <Button label='Save and Submit' disabled={!checkFormForDisable(data?.data1 || []) || !submisisonStatus} onClick={() => { saveSRF() }} className="ml-4" ></Button>}

                                                </div>


                                            </div>

                                        </div>


                                    </div> :
                                    <div className="col-12 card">Form not found</div>
                            }




                        </div>
                        :
                        <div className="col-12 card">Form not found</div>
                        // <div className="col-12 card">You have no rights to access this page </div>

                    }
                </div>
                <Dialog visible={assigneedialog} modal
                    className="p-fluid"
                    onHide={() => {
                        setAssigneeDialog(false);
                    }} style={{ width: '65%' }} header={'Assignees '} >
                    <div>
                        <Accordion multiple activeIndex={[0]}>
                            <AccordionTab header={"Submitter " + (assigneeobj.reporter_ids ? `(${assigneeobj.reporter_ids.length})` : '')}>
                                {userList.filter(i => assigneeobj.reporter_ids.includes(i.id)).map((i, index) => {
                                    return (
                                        <p>{index + 1} . {i.information.empname} </p>
                                    )
                                }
                                )

                                }
                            </AccordionTab>
                            {assigneeobj.reviewer_ids && assigneeobj.reviewer_ids.length !== 0 &&
                                <AccordionTab header={"Reviewer " + (assigneeobj.reviewer_ids ? `(${assigneeobj.reviewer_ids.length})` : '')}>
                                    {userList.filter(i => assigneeobj.reviewer_ids.includes(i.id)).map((i, index) => {
                                        return (
                                            <p>{index + 1} . {i.information.empname} </p>
                                        )
                                    }
                                    )

                                    }
                                </AccordionTab>}

                        </Accordion>
                    </div>
                </Dialog>
                <Dialog visible={remarksdialog} modal
                    className="p-fluid"
                    onHide={() => {
                        setRemarksDialog(false);
                    }} style={{ width: '65%' }} header={'Remarks'} >
                    <div>
                        {
                            remarksdata.map((cmnt) => {
                                return (
                                    <div className="col-12 grid " style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                        <div className="col-5">
                                            <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                            <div className="mt-2" > {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                        </div>
                                        <div className="col-5">
                                            {cmnt.remarks}

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Dialog>
            </StickyContainer>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SRFSubmission, comparisonFn);
