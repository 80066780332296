import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const ESGGChart = ({ data }) => {
  // Function to filter data based on category
  const filterDataByCategory = (category) =>
    data.filter((entry) => entry.category === category);

  // Function to compute average values and max values for comparison
  const aggregateData = (filteredData, categoryType) => {
    const totals = {
      environment_avg: 0,
      social_avg: 0,
      gove_avg: 0,
      gen_avg: 0,
    };

    const maxValues =
      categoryType === "Sales"
        ? {
            Environment: 40,
            Social: 40,
            Governance: 15,
            General: 5,
          }
        : {
            Environment: 40,
            Social: 40,
            Governance: 15,
            General: 5,
          };

    let count = filteredData.length || 1; // Prevent division by zero

    filteredData.forEach((entry) => {
      totals.environment_avg += isNaN(entry.environment_avg)
        ? 0
        : entry.environment_avg;
      totals.social_avg += isNaN(entry.social_avg) ? 0 : entry.social_avg;
      totals.gove_avg += isNaN(entry.gove_avg) ? 0 : entry.gove_avg;
      totals.gen_avg += isNaN(entry.gen_avg) ? 0 : entry.gen_avg;
    });

    const avgEnvironment = totals.environment_avg / count;
    const avgSocial = totals.social_avg / count;
    const avgGovernance = totals.gove_avg / count;
    const avgGeneral = totals.gen_avg / count;

    const clampForGraph = (value) => Math.max(0, value);
    const clampRemaining = (avgValue, maxValue) =>
      Math.max(0, Math.min(maxValue, maxValue - avgValue));

    return [
      {
        category: "Environment",
        avgValue: avgEnvironment,
        avgValueForGraph: clampForGraph(avgEnvironment),
        maxValue: maxValues.Environment,
        remainingToMax: clampRemaining(avgEnvironment, maxValues.Environment),
        achievedColor: "#2C7C69",
        maxColor: "#7FC8A9",
      },
      {
        category: "Social",
        avgValue: avgSocial,
        avgValueForGraph: clampForGraph(avgSocial),
        maxValue: maxValues.Social,
        remainingToMax: clampRemaining(avgSocial, maxValues.Social),
        achievedColor: "#FC6E51",
        maxColor: "#FEB2A8",
      },
      {
        category: "Governance",
        avgValue: avgGovernance,
        avgValueForGraph: clampForGraph(avgGovernance),
        maxValue: maxValues.Governance,
        remainingToMax: clampRemaining(avgGovernance, maxValues.Governance),
        achievedColor: "#4A90E2",
        maxColor: "#AFCBFF",
      },
      {
        category: "General",
        avgValue: avgGeneral,
        avgValueForGraph: clampForGraph(avgGeneral),
        maxValue: maxValues.General,
        remainingToMax: clampRemaining(avgGeneral, maxValues.General),
        achievedColor: "#b0b0b0",
        maxColor: "#e3e3e3",
      },
    ];
  };

  // Aggregating data for both Sales and Service
  const salesData = aggregateData(filterDataByCategory("Sales"), "Sales");
  const serviceData = aggregateData(filterDataByCategory("Service"), "Service");

  // Function to set the Y-Axis domain to a fixed max value
  const getYAxisDomain = () => [0, 40]; // Fixed max value of 40

  const salesYAxisDomain = getYAxisDomain();
  const serviceYAxisDomain = getYAxisDomain();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: 500,
      }}
    >
      {/* Sales Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Sales - ESG Score
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={salesData}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
            barSize={60}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={salesYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              name="Average Score"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            >
              {salesData.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={entry.achievedColor}
                />
              ))}
            </Bar>
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              name="Remaining to Max"
            >
              {salesData.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>
          <div
            className="legend"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <span
                style={{
                  color: "#e3e3e3",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#e3e3e3",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#7FC8A9",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#7FC8A9",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FFB6C1",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FFB6C1",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div style={{ marginRight: "30px" }}>
              <span
                style={{
                  color: "#AFCBFF",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#AFCBFF",
                  marginRight: "5px",
                }}
              ></span>
              Maximum
            </div>
            <div>
              <span
                style={{
                  color: "#b0b0b0",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#b0b0b0",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#2C7C69",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#2C7C69",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FF6F61",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FF6F61",
                  marginRight: "5px",
                }}
              ></span>
            </div>

            <div>
              <span
                style={{
                  color: "#4A90E2",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#4A90E2",
                  marginRight: "5px",
                }}
              ></span>
              Achieved
            </div>
          </div>
        </ResponsiveContainer>
      </div>

      {/* Service Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Service - ESG Score
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={serviceData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={serviceYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              name="Average Score"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            >
              {serviceData.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={entry.achievedColor}
                />
              ))}
            </Bar>
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              name="Remaining to Max"
            >
              {serviceData.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>

          <div
            className="legend"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <span
                style={{
                  color: "#e3e3e3",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#e3e3e3",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#7FC8A9",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#7FC8A9",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FFB6C1",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FFB6C1",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div style={{ marginRight: "30px" }}>
              <span
                style={{
                  color: "#AFCBFF",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#AFCBFF",
                  marginRight: "5px",
                }}
              ></span>
              Maximum
            </div>
            <div>
              <span
                style={{
                  color: "#b0b0b0",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#b0b0b0",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#2C7C69",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#2C7C69",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FF6F61",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FF6F61",
                  marginRight: "5px",
                }}
              ></span>
            </div>

            <div>
              <span
                style={{
                  color: "#4A90E2",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#4A90E2",
                  marginRight: "5px",
                }}
              ></span>
              Achieved
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ESGGChart;
