import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

import moment from 'moment';
import axios from 'axios';

import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'

import { CascadeSelect } from 'primereact/cascadeselect';

import * as XLSX from 'xlsx'
import FileSaver, { saveAs } from 'file-saver';
import { setRef } from '@fullcalendar/core';
import { SplitButton } from 'primereact/splitbutton';
import Swal from 'sweetalert2';


import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"

import cx from "classnames";

import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from 'jquery'
import { API } from '../../../../constants/api_url';
import APIServices from '../../../../service/APIService';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { DateTime } from 'luxon';
import { TabMenu } from 'primereact/tabmenu';
import { fetchUserList } from '../../../../RTK/Background/userProfileList';
const google = window.google;
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const AddClientDealerTVS = () => {

    const [data, setData] = useState([])
    const [databk, setDataBk] = useState([])
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()
    const [allimporteddata, setAllImportedData] = useState([])
    const [importentries, setImportEntries] = useState({ invalidRows: [], validRows: [], actualRows: [] })
    const [importdialog, setImportDialog] = useState(false)
    const [apidata, setAPiData] = useState([])
    const allRoles = useSelector((state) => state.user.allRoles)
    const [filter2, setFilter2] = useState('')
    const [dealer, setDealer] = useState({
        assessmentStartMonth: null,
        supplierCode: '',
        dealerName: '',
        email: '',
        dealerSPOC: '',
        dealerCategory: null,
        dealerZone: null,
        dealerLocation: '',
        dealerCountry: '',
        dealerAO: '',
        service: {
            areaManagerName: '',
            areaManagerMailId: '',
            zonalPlannerName: '',
            zonalPlannerMailId: '',
            regionalManagerName: '',
            regionalManagerMailId: '',
        },
        sales: {
            areaManagerName: '',
            areaManagerMailId: '',
            zonalPlannerName: '',
            zonalPlannerMailId: '',
            regionalManagerName: '',
            regionalManagerMailId: '',
        },
        aps: {
            areaManagerName: '',
            areaManagerMailId: '',
            regionalManagerName: '',
            regionalManagerMailId: '',
            hoPlannerName: '',
            hoPlannerMailId: '',
        },
        ao: {
            areaCommercialManagerName: '',
            areaCommercialManagerMailId: '',
            regionalCommercialManagerName: '',
            regionalCommercialManagerMailId: '',
        },
    })
    const categoryList = [{ name: 'Two Wheeler', value: 1 }]
    const zonalOfficeList = [{ name: "Central", value: 1 }, { name: "East", value: 2 }, { name: "North", value: 3 }, { name: "South", value: 9 }, { name: "South1", value: 4 }, { name: "South2", value: 5 }, { name: "West", value: 8 }, { name: "West1", value: 6 }, { name: "West2", value: 7 }]

    const dealerType = [{ name: 'Authorized Main Dealer', value: 1 }, { name: 'Authorized Dealer', value: 2 }, { name: 'Authorized Parts Stockist (APS)', value: 3 }, { name: 'Area Office', value: 4 }]
    const excelHeader = [
        "Category",
        "Branch Code",
        "Name",
        "Country",
        "Zone",
        "Location",
        "AO",
        "SPOC Name",
        "Email Id",
        "Area Manager Service Emp Name",
        "Area Manager Service Emp Email Id",
        "Zonal Planner Service Emp Name",
        "Zonal Planner Service Emp Mail Id",
        "Regional Manager Service EMP Name",
        "Regional Manager Service Email Id",
        "Area Manager Sales Emp Name",
        "Area Manager Sales Emp Email Id",
        "Zonal Planner Sales Emp Name",
        "Zonal Planner Sales Emp Mail Id",
        "Regional Manager Sales EMP Name",
        "Regional Manager Sales Email Id",
        "Area Parts Manager APS Emp Name",
        "Area Parts Manager APS Emp Email Id",
        "Regional Parts Manager APS EMP Name",
        "Regional Parts Manager APS Email Id",
        "HO Parts Planner Name",
        "HO Parts Planner Email Id",
        "Area Commercial Manager APS Emp Name",
        "Area Commercial Manager APS Emp Email Id",
        "Regional Commercial Manager APS EMP Name",
        "Regional Commercial Manager APS EMP Email Id"
    ]
    useEffect(() => {
        return () => {
            dispatch(fetchUserList(admin_data.id))
        }
    }, [])
    const propertyTypeList = [{ name: 'Own property', value: 1 }, { name: 'Rented property', value: 2 }]

    const [adddealerdialog, setAddDealerDialog] = useState(false)

    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)

    const [submitted, setSubmitted] = useState(false)

    const forceUpdate = useForceUpdate()
    const dt = useRef(null);




    useEffect(() => {

        renderData()

        let uriString = {
            "include": ["locationTwos"]

        }


    }, [])

    const renderData = () => {
        let datas = []

        APIServices.get(API.Dealer_UP(admin_data.id))
            .then((res) => {

                setAPiData(res.data)
                setData(res.data)
                setDataBk(res.data)
            })




    }
    const updateDealer = (obj, val) => {
        let loc = dealer
        if (obj.includes('.')) {
            let arr = obj.split('.')
            loc[arr[0]][arr[1]] = val
        } else {
            loc[obj] = val

        }
        console.log(loc)
        setDealer(loc)
        forceUpdate()
    }
    const editDealer = (rowData) => {
        let loc = JSON.parse(JSON.stringify(rowData))
        rowData.supplierCode = rowData.code
        delete loc.code
        if (rowData.assessmentStartMonth) {
            rowData.assessmentStartMonth = DateTime.fromISO(rowData.assessmentStartMonth, { zone: "utc" }).toJSDate()
        }
        setDealer(rowData)
        setAddDealerDialog(true)
    }
    const dealerCategoryTemplate = (rowData) => {

        return (
            <div >

                {categoryList.find(x => x.id === rowData.category)?.name || 'NA'}
            </div>
        );
    }
    const triggerMonthTemplate = (rowData) => {

        return (
            <div >

                {rowData.assessmentStartMonth ? DateTime.fromISO(rowData.assessmentStartMonth, { zone: "Asia/Calcutta" }).toFormat('LLL-yyyy') : 'Not Set'}
            </div>
        );
    }
    const dealerTypeTemplate = (rowData) => {

        return (
            <div >

                {dealerType.find(x => x.id === rowData.dealerType)?.name || 'NA'}
            </div>
        );
    }
    const zoneTypeTemplate = (rowData) => {

        return (
            <div >

                {zonalOfficeList.find(x => x.value === rowData.dealerZone)?.name || 'NA'}
            </div>
        );
    }
    const dealerNameTemplate = (rowData) => {

        return (
            <div className='cur-pointer text-underline' onClick={() => { editDealer(rowData) }}>

                {rowData.dealerName}
            </div>
        );
    }
    const emailTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empmailid}
            </>
        );
    }
    const addNewDealer = () => {

        setSubmitted(true)
        if (!dealer.id) {
            if (checkValidMailID(dealer.service?.areaManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.service?.zonalPlannerMailId?.trim(), 1) &&
                checkValidMailID(dealer.service?.regionalManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.sales?.areaManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.sales?.zonalPlannerMailId?.trim(), 1) &&
                checkValidMailID(dealer.sales?.regionalManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.aps?.areaManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.aps?.regionalManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.aps?.hoPlannerMailId?.trim(), 1) &&
                checkValidMailID(dealer.ao?.areaCommercialManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.ao?.regionalCommercialManagerMailId?.trim(), 1) && dealer.supplierCode && dealer.supplierCode.trim().length && dealer.dealerName && dealer.email && checkValidMailID(dealer.email) && dealer.dealerCategory && dealer.dealerZone && dealer.dealerName.trim().length !== 0 && dealer.dealerSPOC.trim().length !== 0 && dealer.dealerAO.trim().length !== 0 && dealer.dealerLocation.trim().length !== 0) {
                console.log(dealer)
                let newObj = {
                    role: 'clientdealer',
                    supplierCode: dealer.supplierCode,
                    clientId: admin_data.id,
                    userId: login_data.id.toString(),

                    email: dealer.email,
                    information: {
                        dealerName: dealer.dealerName,
                        dealerSPOC: dealer.dealerSPOC,
                        dealerName: dealer.dealerName,
                        dealerSPOC: dealer.dealerSPOC,
                        dealerCategory: dealer.dealerCategory,
                        dealerZone: dealer.dealerZone,
                        dealerLocation: dealer.dealerLocation,
                        dealerCountry: dealer.dealerCountry,
                        dealerAO: dealer.dealerAO,

                        service: {
                            areaManagerName: dealer?.service?.areaManagerName || '',
                            areaManagerMailId: dealer?.service?.areaManagerMailId || '',
                            zonalPlannerName: dealer?.service?.zonalPlannerName || '',
                            zonalPlannerMailId: dealer?.service?.zonalPlannerMailId || '',
                            regionalManagerName: dealer?.service?.regionalManagerName || '',
                            regionalManagerMailId: dealer?.service?.regionalManagerMailId || ''
                        },
                        sales: {
                            areaManagerName: dealer?.sales?.areaManagerName || '',
                            areaManagerMailId: dealer?.sales?.areaManagerMailId || '',
                            zonalPlannerName: dealer?.sales?.zonalPlannerName || '',
                            zonalPlannerMailId: dealer?.sales?.zonalPlannerMailId || '',
                            regionalManagerName: dealer?.sales?.regionalManagerName || '',
                            regionalManagerMailId: dealer?.sales?.regionalManagerMailId || ''
                        },
                        aps: {
                            areaManagerName: dealer?.aps?.areaManagerName || '',
                            areaManagerMailId: dealer?.aps?.areaManagerMailId || '',
                            regionalManagerName: dealer?.aps?.regionalManagerName || '',
                            regionalManagerMailId: dealer?.aps?.regionalManagerMailId || '',
                            hoPlannerName: dealer?.aps?.hoPlannerName || '',
                            hoPlannerMailId: dealer?.aps?.hoPlannerMailId || ''
                        },
                        ao: {
                            areaCommercialManagerName: dealer?.ao?.areaCommercialManagerName || '',
                            areaCommercialManagerMailId: dealer?.ao?.areaCommercialManagerMailId || '',
                            regionalCommercialManagerName: dealer?.ao?.regionalCommercialManagerName || '',
                            regionalCommercialManagerMailId: dealer?.ao?.regionalCommercialManagerMailId || ''
                        }
                    }
                }




                console.log(newObj)
                APIServices.post(API.External_Dealer_UP, newObj)
                    .then((res) => {


                        Swal.fire({
                            position: 'center',

                            title: res?.data?.message || 'No message',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        if (res?.data?.status === false) {

                        } else {
                            renderData()
                        }

                    }).catch((e) => {


                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
                setAddDealerDialog(false)

                setSubmitted(false)

            }
        } else {
            if (checkValidMailID(dealer.service?.areaManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.service?.zonalPlannerMailId?.trim(), 1) &&
                checkValidMailID(dealer.service?.regionalManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.sales?.areaManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.sales?.zonalPlannerMailId?.trim(), 1) &&
                checkValidMailID(dealer.sales?.regionalManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.aps?.areaManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.aps?.regionalManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.aps?.hoPlannerMailId?.trim(), 1) &&
                checkValidMailID(dealer.ao?.areaCommercialManagerMailId?.trim(), 1) &&
                checkValidMailID(dealer.ao?.regionalCommercialManagerMailId?.trim(), 1) && dealer.dealerName && dealer.dealerCategory && dealer.dealerZone && dealer.dealerName.trim().length !== 0 && dealer.dealerSPOC.trim().length !== 0 && dealer.dealerAO.trim().length !== 0 && dealer.dealerLocation.trim().length !== 0) {
                console.log(dealer.regionalCommercialManagerMailId)
                let newObj = {
                    assessmentStartMonth: dealer.assessmentStartMonth,
                    modified_on: DateTime.utc(),
                    modified_by: login_data.id,
                    dealerName: dealer.dealerName,
                    dealerSPOC: dealer.dealerSPOC,
                    dealerCategory: dealer.dealerCategory,
                    dealerZone: dealer.dealerZone,
                    dealerLocation: dealer.dealerLocation,
                    dealerCountry: dealer.dealerCountry,
                    dealerAO: dealer.dealerAO,
                    service: {
                        areaManagerName: dealer?.service?.areaManagerName || '',
                        areaManagerMailId: dealer?.service?.areaManagerMailId || '',
                        zonalPlannerName: dealer?.service?.zonalPlannerName || '',
                        zonalPlannerMailId: dealer?.service?.zonalPlannerMailId || '',
                        regionalManagerName: dealer?.service?.regionalManagerName || '',
                        regionalManagerMailId: dealer?.service?.regionalManagerMailId || ''
                    },
                    sales: {
                        areaManagerName: dealer?.sales?.areaManagerName || '',
                        areaManagerMailId: dealer?.sales?.areaManagerMailId || '',
                        zonalPlannerName: dealer?.sales?.zonalPlannerName || '',
                        zonalPlannerMailId: dealer?.sales?.zonalPlannerMailId || '',
                        regionalManagerName: dealer?.sales?.regionalManagerName || '',
                        regionalManagerMailId: dealer?.sales?.regionalManagerMailId || ''
                    },
                    aps: {
                        areaManagerName: dealer?.aps?.areaManagerName || '',
                        areaManagerMailId: dealer?.aps?.areaManagerMailId || '',
                        regionalManagerName: dealer?.aps?.regionalManagerName || '',
                        regionalManagerMailId: dealer?.aps?.regionalManagerMailId || '',
                        hoPlannerName: dealer?.aps?.hoPlannerName || '',
                        hoPlannerMailId: dealer?.aps?.hoPlannerMailId || ''
                    },
                    ao: {
                        areaCommercialManagerName: dealer?.ao?.areaCommercialManagerName || '',
                        areaCommercialManagerMailId: dealer?.ao?.areaCommercialManagerMailId || '',
                        regionalCommercialManagerName: dealer?.ao?.regionalCommercialManagerName || '',
                        regionalCommercialManagerMailId: dealer?.ao?.regionalCommercialManagerMailId || ''
                    }
                }
                APIServices.patch(API.VendorCode_Edit(dealer.id), newObj)
                    .then((res) => {

                        setAddDealerDialog(false)
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `Updated successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                        renderData()
                    }).catch((e) => {


                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
            }
        }


    }
    const sendMail = (rowData) => {

        return (
            <div onClick={() => {
                console.log(rowData)
                APIServices.post(API.DealerResend, { userId: rowData.userProfileId, vendorId: rowData.id }).then((res) => {
                    renderData()
                    Swal.fire({
                        position: 'center',
                        title: 'Email Sent To Dealer',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }}>

                {!rowData.emailSentCount ? 'Send Mail' : 'Resend Mail'}
            </div>
        );
    }
    const resetSPOC = (rowData) => {

        return (
            <div onClick={() => {
                console.log(rowData)
                APIServices.post(API.DealerResend, { userId: rowData.userProfileId, vendorId: rowData.id }).then((res) => {
                    renderData()
                    Swal.fire({
                        position: 'center',
                        title: 'Email Sent To Dealer',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }}>

                change
            </div>
        );
    }
    const downloadErrorReport = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);

        // Create a workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dealers");

        // Write file and trigger download
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, "Dealers_Multiple_Error_Import.xlsx");
    }
    const updateLocationData = async () => {
        let pincode = document.getElementById('pincode').value

        if (pincode) {
            var geocoder = new google.maps.Geocoder();


            try {

                geocoder.geocode({ address: pincode }, function (results, status) {
                    if (status === "OK") {
                        // Successfully retrieved geocoding data
                        console.log("Geocoding Results:", results);

                        const location = results[0].geometry.location;

                        // Get latitude and longitude
                        const latitude = location.lat();
                        const longitude = location.lng();
                        let loc = dealer
                        loc.pinCode = pincode
                        loc['placeId'] = results[0].place_id
                        loc.coords = { lng: longitude, lat: latitude }
                        setDealer(loc)
                        forceUpdate()

                    } else {
                        console.error("Geocoding failed due to:", status);
                        let loc = dealer
                        loc.pinCode = ''
                        loc.coords = { lng: null, lat: null }
                        loc['placeId'] = undefined
                        setDealer(loc)
                        forceUpdate()
                    }
                });
            } catch (error) {
                console.error('Error during Geocoding Request:', error.message);
                let loc = dealer
                loc.pinCode = ''
                loc.coords = { lng: null, lat: null }
                loc['placeId'] = undefined
                setDealer(loc)
                forceUpdate()
                return null;
            }
        }
    }
    const downloadExcelTemplate = () => {
        // Convert data to worksheet
        const emptyData = [];

        // Convert to worksheet
        const worksheet = XLSX.utils.json_to_sheet(emptyData, { header: excelHeader });

        // Insert headers manually (ensuring they start from A1)
        XLSX.utils.sheet_add_aoa(worksheet, [excelHeader], { origin: "A1" });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, 'Dealer_Bulk_Upload_Template.xlsx');

    }
    const importDealerFromExcel = (e) => {
        const file = e.files[0]
        const reader = new FileReader();
        reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: "array" }); // Read as array
            const sheetName = workbook.SheetNames[0];
            const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            Swal.fire({
                title: 'Please Wait !',
                html: 'Validating fields for ',// add html attribute if you want or remove
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });
            e.options.clear()

            const result = await validateExcel(data);
            Swal.close()
            if (result.validRows.length || result.invalidRows.length) {
                setImportEntries(result)
                setImportDialog(true)
            } else {

                Swal.fire({
                    position: 'center',
                    title: 'No Data to import',
                    showConfirmButton: false,
                    timer: 1500
                })
            }

            console.log("Valid Rows:", result.validRows);
            console.log("Invalid Rows:", result.invalidRows);
        };

        reader.readAsArrayBuffer(file);
    }
    const validateExcel = (data) => {
        const validRows = [];
        const invalidRows = [];
        const actualRows = [];
        return new Promise((resolve) => {
            const isValidEmail = (email) => !email || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

            const getCategoryOrZoneId = (name, mapping) => {
                if (!name) return null;
                const match = mapping.find((item) => item.name.toLowerCase() === name.toLowerCase());
                return match ? match.value : null;
            };

            for (const row of data) {
                const errors = [];
                const {
                    Category,
                    "Branch Code": BranchCode,
                    Name,
                    Country,
                    Zone,
                    Location,
                    AO,
                    "SPOC Name": SPOCName,
                    "Email Id": EmailId,
                    "Area Manager Service Emp Name": areaManagerServiceName,
                    "Area Manager Service Emp Email Id": areaManagerServiceEmail,
                    "Zonal Planner Service Emp Name": zonalPlannerServiceName,
                    "Zonal Planner Service Emp Mail Id": zonalPlannerServiceEmail,
                    "Regional Manager Service EMP Name": regionalManagerServiceName,
                    "Regional Manager Service Email Id": regionalManagerServiceEmail,
                    "Area Manager Sales Emp Name": areaManagerSalesName,
                    "Area Manager Sales Emp Email Id": areaManagerSalesEmail,
                    "Zonal Planner Sales Emp Name": zonalPlannerSalesName,
                    "Zonal Planner Sales Emp Mail Id": zonalPlannerSalesEmail,
                    "Regional Manager Sales EMP Name": regionalManagerSalesName,
                    "Regional Manager Sales Email Id": regionalManagerSalesEmail,
                    "Area Parts Manager APS Emp Name": areaManagerApsName,
                    "Area Parts Manager APS Emp Email Id": areaManagerApsEmail,
                    "Regional Parts Manager APS EMP Name": regionalManagerApsName,
                    "Regional Parts Manager APS Email Id": regionalManagerApsEmail,
                    "HO Parts Planner Name": hoPlannerApsName,
                    "HO Parts Planner Email Id": hoPlannerApsEmail,
                    "Area Commercial Manager APS Emp Name": areaCommercialManagerName,
                    "Area Commercial Manager APS Emp Email Id": areaCommercialManagerEmail,
                    "Regional Commercial Manager APS EMP Name": regionalCommercialManagerName,
                    "Regional Commercial Manager APS EMP Email Id": regionalCommercialManagerEmail
                } = row;

                // Validate mandatory fields
                if (!Category) errors.push("Category is required.");
                if (!BranchCode) errors.push("Branch Code is required.");
                if (!Name) errors.push("Dealer Name is required.");
                if (!Country) errors.push("Country is required.");
                if (!Zone) errors.push("Zone is required.");
                if (!Location) errors.push("Location is required.");
                if (!AO) errors.push("AO is required.");
                if (!SPOCName) errors.push("SPOC Name is required.");
                if (!EmailId || !isValidEmail(EmailId)) errors.push("Invalid or missing Email Id.");

                // Map Category and Zone to IDs
                const categoryId = getCategoryOrZoneId(Category, dealerType);
                const zoneId = getCategoryOrZoneId(Zone, zonalOfficeList);

                if (!categoryId) errors.push("Invalid Category name.");
                if (!zoneId) errors.push("Invalid Zone name.");

                // Convert BranchCode to string
                const branchCodeString = BranchCode ? String(BranchCode) : "";

                // Construct the validated row
                const validatedRow = {
                    role: 'clientdealer',
                    supplierCode: branchCodeString,
                    clientId: admin_data.id,
                    userId: login_data.id,
                    created_on: DateTime.utc(),
                    email: EmailId,
                    information: {
                        dealerName: Name,
                        dealerSPOC: SPOCName,
                        dealerCategory: categoryId,
                        dealerZone: zoneId,
                        dealerLocation: Location,
                        dealerCountry: Country,
                        dealerAO: AO,
                        service: {
                            areaManagerName: areaManagerServiceName || '',
                            areaManagerMailId: areaManagerServiceEmail || '',
                            zonalPlannerName: zonalPlannerServiceName || '',
                            zonalPlannerMailId: zonalPlannerServiceEmail || '',
                            regionalManagerName: regionalManagerServiceName || '',
                            regionalManagerMailId: regionalManagerServiceEmail || ''
                        },
                        sales: {
                            areaManagerName: areaManagerSalesName || '',
                            areaManagerMailId: areaManagerSalesEmail || '',
                            zonalPlannerName: zonalPlannerSalesName || '',
                            zonalPlannerMailId: zonalPlannerSalesEmail || '',
                            regionalManagerName: regionalManagerSalesName || '',
                            regionalManagerMailId: regionalManagerSalesEmail || ''
                        },
                        aps: {
                            areaManagerName: areaManagerApsName || '',
                            areaManagerMailId: areaManagerApsEmail || '',
                            regionalManagerName: regionalManagerApsName || '',
                            regionalManagerMailId: regionalManagerApsEmail || '',
                            hoPlannerName: hoPlannerApsName || '',
                            hoPlannerMailId: hoPlannerApsEmail || ''
                        },
                        ao: {
                            areaCommercialManagerName: areaCommercialManagerName || '',
                            areaCommercialManagerMailId: areaCommercialManagerEmail || '',
                            regionalCommercialManagerName: regionalCommercialManagerName || '',
                            regionalCommercialManagerMailId: regionalCommercialManagerEmail || ''
                        }
                    }
                };

                // Classify row as valid or invalid
                if (errors.length > 0) {
                    invalidRows.push({ ...row, Remarks: errors.join(", ") });
                } else {
                    validRows.push(row);
                    actualRows.push(validatedRow)
                }
            }

            resolve({ validRows, invalidRows, actualRows })
        })
        // Helper functions

    }

    const header2 = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="flex col-12 justify-content-between" >
                    <div className='grid m-0 p-0 col-6'>
                        <Button label="Download Template" style={{ width: 165 }} className='mr-2' onClick={() => { downloadExcelTemplate() }} />
                        <FileUpload
                            chooseOptions={{
                                label: "Import Dealer",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importDealerFromExcel(e);
                            }}
                        />


                    </div>
                    <Button label="New Dealer" icon="pi pi-plus" style={{ width: 150 }} onClick={() => {
                        setDealer({
                            assessmentStartMonth: null,
                            supplierCode: '',
                            dealerName: '',
                            email: '',
                            dealerSPOC: '',
                            dealerCategory: null,
                            dealerZone: null,
                            dealerLocation: '',
                            dealerCountry: '',
                            dealerAO: '',
                            service: {
                                areaManagerName: '',
                                areaManagerMailId: '',
                                zonalPlannerName: '',
                                zonalPlannerMailId: '',
                                regionalManagerName: '',
                                regionalManagerMailId: '',
                            },
                            sales: {
                                areaManagerName: '',
                                areaManagerMailId: '',
                                zonalPlannerName: '',
                                zonalPlannerMailId: '',
                                regionalManagerName: '',
                                regionalManagerMailId: '',
                            },
                            aps: {
                                areaManagerName: '',
                                areaManagerMailId: '',
                                regionalManagerName: '',
                                regionalManagerMailId: '',
                                hoPlannerName: '',
                                hoPlannerMailId: '',
                            },
                            ao: {
                                areaCommercialManagerName: '',
                                areaCommercialManagerMailId: '',
                                regionalCommercialManagerName: '',
                                regionalCommercialManagerMailId: '',
                            },
                        }); setSubmitted(false); setAddDealerDialog(true)
                    }} />

                </div>
            </React.Fragment>

        </div>
    )

    const addDealerDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewDealer() }} />

        </div>)
    }
    const downloadReport = (actual) => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(actual);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer);
        });
    }
    const saveAsExcelFile = (buffer) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, 'TVSM_Dealer_Import_Report_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const uploadDealer = (data) => {
        setImportDialog(false)
        Swal.fire({
            title: 'Please Wait !',
            html: 'Importing dealers into system, dont reload page ',// add html attribute if you want or remove
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        APIServices.post(API.BulkDealerImport, data).then(async (res) => {
            console.log(res)
            if (!res.data.result) {
                const { value: accept } = await Swal.fire({
                    title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Error Report</div>`,
                    html: `<div style="overflow:auto;max-height:200px" >Error found in ${res?.data?.rejectedSupplier?.length} entries, download error report to more details</div>`,

                    confirmButtonColor: '#315975',
                    showCancelButton: true,

                    confirmButtonText:
                        'Download',

                })
                if (accept) {
                    downloadErrorReport(res?.data?.rejectedSupplier || [])
                }


            }
            renderData()
            Swal.close()
        }).catch((e) => {
            Swal.close()
        })
    }
    const checkValidMailID = (email, type) => {
        if (type) {
            if (typeof email === 'string' && email.trim().length) {
                return String(email)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            } else {
                return true
            }
        }
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const vendorCodeFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data)).map(i => i.code).filter(i => i)

        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={allentity}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const zoneFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(id => zonalOfficeList.find(x => x.value === id.dealerZone) ? zonalOfficeList.find(x => x.value === id.dealerZone) : { name: 'Not Found', value: null })))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                optionValue='value'
                optionLabel='name'
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const categoryFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(id => dealerType.find(x => x.value === id.dealerCategory) ? dealerType.find(x => x.value === id.dealerCategory) : { name: 'Not Found', value: null })))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                optionValue='value'
                optionLabel='name'
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const searchDF = (e) => {
        let loc = search;
        console.log(databk)
        setData(
            databk.filter((k) => {
                const searchText = e.target.value.trim().toLowerCase();

                const match = (val) => (val ? val.trim().toLowerCase().includes(searchText) : false);

                return (
                    match(k?.email) ||
                    match(k?.code) ||
                    match(k?.dealerName) ||
                    match(k?.dealerLocation) ||

                    // Service Fields
                    match(k?.service?.areaManagerName) ||
                    match(k?.service?.areaManagerMailId) ||
                    match(k?.service?.zonalPlannerName) ||
                    match(k?.service?.zonalPlannerMailId) ||
                    match(k?.service?.regionalManagerName) ||
                    match(k?.service?.regionalManagerMailId) ||

                    // Sales Fields
                    match(k?.sales?.areaManagerName) ||
                    match(k?.sales?.areaManagerMailId) ||
                    match(k?.sales?.zonalPlannerName) ||
                    match(k?.sales?.zonalPlannerMailId) ||
                    match(k?.sales?.regionalManagerName) ||
                    match(k?.sales?.regionalManagerMailId) ||

                    // APS Fields
                    match(k?.aps?.areaManagerName) ||
                    match(k?.aps?.areaManagerMailId) ||
                    match(k?.aps?.regionalManagerName) ||
                    match(k?.aps?.regionalManagerMailId) ||
                    match(k?.aps?.hoPlannerName) ||
                    match(k?.aps?.hoPlannerMailId) ||

                    // AO Fields
                    match(k?.ao?.areaCommercialManagerName) ||
                    match(k?.ao?.areaCommercialManagerMailId) ||
                    match(k?.ao?.regionalCommercialManagerName) ||
                    match(k?.ao?.regionalCommercialManagerMailId)
                );
            })
        );

        setSearch(loc)
    }
    return (
        <div className="grid p-fluid">
            {(login_data.role === 'clientadmin' || allRoles.includes(12) || allRoles.includes(2)) ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{

                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`Dealer (${data.length})`} </h5>
                    </div>

                    <div>
                        <div className="col-12 flex justify-content-end" >
                            <div className='col-5'>
                                <span className="p-input-icon-left" style={{ width: '100%' }}>
                                    <i className="pi pi-search" />
                                    <InputText value={search.df} style={{ width: '100%' }} onChange={searchDF} placeholder="Search Code/Name/Location/Mail" />
                                </span>
                            </div>
                        </div>
                        <DataTable ref={dt} value={data} filters={{ code: { value: null, matchMode: 'in' }, dealerZone: { value: null, matchMode: 'in' }, dealerCategory: { value: null, matchMode: 'in' } }}
                            scrollable globalFilterFields={['dealerName']} globalFilter={filter2}
                            dataKey="id" paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} className="datatable-responsive h-max"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            emptyMessage="No records found." header={header2} responsiveLayout="scroll">
                            {/* <Column field="config" header="Role Config" body={configTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column> */}
                            <Column header="Dealer Code" field={'code'} headerStyle={{ width: '14%', minWidth: '10rem' }} filter showFilterMatchModes={false} showApplyButton={true} filterElement={vendorCodeFilterTemplate}  ></Column>
                            <Column field="dealerName" header="Name" body={dealerNameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="dealerSPOC" header="SPOC Name" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="email" header="Email" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Category" field={'dealerCategory'} headerStyle={{ width: '14%', minWidth: '10rem' }} body={dealerTypeTemplate} filter showFilterMatchModes={false} showApplyButton={true} filterElement={categoryFilterTemplate} ></Column>
                            <Column header="Zone" field={'dealerZone'} headerStyle={{ width: '14%', minWidth: '10rem' }} body={zoneTypeTemplate} filter showFilterMatchModes={false} showApplyButton={true} filterElement={zoneFilterTemplate} ></Column>
                            <Column field="dealerLocation" header="Location" ></Column>
                            <Column field="dealerAO" header="Area Office" ></Column>
                            <Column header="Self Assessment Month" headerStyle={{ width: '14%', minWidth: '10rem' }} body={triggerMonthTemplate}></Column>
                            <Column header="Re-send Mail" body={sendMail}></Column>
                            <Column header="Action" body={resetSPOC}></Column>

                            <Column field="service.areaManagerName" header="Area Manager Service Emp Name"></Column>
                            <Column field="service.areaManagerMailId" header="Area Manager Service Emp Email Id"></Column>
                            <Column field="service.zonalPlannerName" header="Zonal Planner Service Emp Name"></Column>
                            <Column field="service.zonalPlannerMailId" header="Zonal Planner Service Emp Mail Id"></Column>
                            <Column field="service.regionalManagerName" header="Regional Manager Service EMP Name"></Column>
                            <Column field="service.regionalManagerMailId" header="Regional Manager Service Email Id"></Column>

                            <Column field="sales.areaManagerName" header="Area Manager Sales Emp Name"></Column>
                            <Column field="sales.areaManagerMailId" header="Area Manager Sales Emp Email Id"></Column>
                            <Column field="sales.zonalPlannerName" header="Zonal Planner Sales Emp Name"></Column>
                            <Column field="sales.zonalPlannerMailId" header="Zonal Planner Sales Emp Mail Id"></Column>
                            <Column field="sales.regionalManagerName" header="Regional Manager Sales EMP Name"></Column>
                            <Column field="sales.regionalManagerMailId" header="Regional Manager Sales Email Id"></Column>

                            <Column field="aps.areaManagerName" header="Area Parts Manager APS Emp Name"></Column>
                            <Column field="aps.areaManagerMailId" header="Area Parts Manager APS Emp Email Id"></Column>
                            <Column field="aps.regionalManagerName" header="Regional Parts Manager APS EMP Name"></Column>
                            <Column field="aps.regionalManagerMailId" header="Regional Parts Manager APS Email Id"></Column>
                            <Column field="aps.hoPlannerName" header="HO Parts Planner Name"></Column>
                            <Column field="aps.hoPlannerMailId" header="HO Parts Planner Email Id"></Column>

                            <Column field="ao.areaCommercialManagerName" header="Area Commercial Manager APS Emp Name"></Column>
                            <Column field="ao.areaCommercialManagerMailId" header="Area Commercial Manager APS Emp Email Id"></Column>
                            <Column field="ao.regionalCommercialManagerName" header="Regional Commercial Manager APS EMP Name"></Column>
                            <Column field="ao.regionalCommercialManagerMailId" header="Regional Commercial Manager APS EMP Email Id"></Column>


                        </DataTable>
                    </div>







                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }


            <Dialog visible={adddealerdialog} style={{ width: '450px' }} header={dealer.id ? "Edit Dealer" : "Add Dealer"} modal className="p-fluid" footer={addDealerDialogFooter} onHide={() => { setSubmitted(false); setAddDealerDialog(false) }}>

                <div className="field">
                    <label htmlFor="refdate">Dealer Name <span style={{ color: 'red' }}>*</span></label>
                    <InputText type={'text'} value={dealer.dealerName} onChange={(e) => { updateDealer('dealerName', e.target.value) }} placeholder="Name" />
                    {submitted && dealer.dealerName.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Dealer Name is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Dealer ID <span style={{ color: 'red' }}>*</span></label>

                    <InputText type={'text'} disabled={dealer.id} value={dealer.id ? dealer.code : dealer.supplierCode} onChange={(e) => { updateDealer('supplierCode', e.target.value) }} placeholder="Vendor Code" />
                    {submitted && (!dealer.supplierCode || dealer.supplierCode.trim().length === 0) && <small className="p-invalid" style={{ color: 'red' }}>Dealer Id is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">SPOC Name<span style={{ color: 'red' }}>*</span></label>
                    <InputText value={dealer.dealerSPOC} onChange={(e) => { updateDealer('dealerSPOC', e.target.value) }} placeholder="SPOC Name" />
                    {submitted && dealer.dealerSPOC.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>SPOC Name is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Dealer E-Mail ID / Login ID <span style={{ color: 'red' }}>*</span></label>
                    <InputText type={'email'} disabled={dealer.id} value={dealer.email} placeholder="Mail ID" onChange={(e) => { updateDealer('email', e.target.value) }} />
                    {submitted && dealer.email && !checkValidMailID(dealer.email) && <small className="p-invalid" style={{ color: 'red' }}>SPOC Name is mandatory</small>}


                </div>
                <div className="field">
                    <label htmlFor="refdate">Country<span style={{ color: 'red' }}>*</span></label>
                    <InputText value={dealer.dealerCountry} onChange={(e) => { updateDealer('dealerCountry', e.target.value) }} placeholder="Country" />
                    {submitted && dealer.dealerCountry.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Country Name is mandatory</small>}

                </div>

                <div className="field">
                    <label htmlFor="refdate">Zone <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.dealerZone} optionLabel='name' onChange={(e) => { updateDealer('dealerZone', e.target.value) }} placeholder="Type" options={zonalOfficeList} />
                    {submitted && dealer.dealerZone === null && <small className="p-invalid" style={{ color: 'red' }}>Dealer Zone is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Area Office<span style={{ color: 'red' }}>*</span></label>
                    <InputText value={dealer.dealerAO} onChange={(e) => { updateDealer('dealerAO', e.target.value) }} placeholder="AO" />
                    {submitted && (!dealer.dealerAO || !dealer.dealerAO.trim().length === 0) && <small className="p-invalid" style={{ color: 'red' }}>Area Office is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Location <span style={{ color: 'red' }}>*</span></label>

                    <InputText value={dealer.dealerLocation} onChange={(e) => { updateDealer('dealerLocation', e.target.value) }} placeholder="Location" />

                    {submitted && (!dealer.dealerLocation || !dealer.dealerLocation?.trim().length) && <small className="p-invalid" style={{ color: 'red' }}>Dealer Location is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Category <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.dealerCategory} optionLabel='name' onChange={(e) => { updateDealer('dealerCategory', e.target.value) }} placeholder="Category" options={dealerType} />
                    {submitted && dealer.dealerCategory === null && <small className="p-invalid" style={{ color: 'red' }}>Dealer Category is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Trigger Self Assessment Start Month </label>
                    <Calendar view={'month'} dateFormat={'M-yy'} value={dealer.assessmentStartMonth} onChange={(e) => { updateDealer('assessmentStartMonth', e.value) }} placeholder="Start Month" />
                </div>



                <label className='fw-6 fs-18 clr-navy mb-2'>Service Details</label>
                <div className="field">
                    <label htmlFor="areaManagerName"> Area Manager Employee Name</label>
                    <InputText value={dealer.service?.areaManagerName || ''} onChange={(e) => { updateDealer('service.areaManagerName', e.target.value) }} placeholder="Area Manager Name" />

                </div>
                <div className="field">
                    <label htmlFor="areaManagerMailId"> Area Manager Email</label>
                    <InputText value={dealer.service?.areaManagerMailId || ''} onChange={(e) => { updateDealer('service.areaManagerMailId', e.target.value) }} placeholder="Area Manager Email" />
                    {submitted && typeof dealer.service?.areaManagerMailId === 'string' && !checkValidMailID(dealer.service?.areaManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>
                <div className="field">
                    <label htmlFor="areaManagerName"> Zonal Planner Employee Name</label>
                    <InputText value={dealer.service?.zonalPlannerName || ''} onChange={(e) => { updateDealer('service.zonalPlannerName', e.target.value) }} placeholder="Zonal Planner Name" />

                </div>
                <div className="field">
                    <label htmlFor="areaManagerMailId"> Zonal Planner Email</label>
                    <InputText value={dealer.service?.zonalPlannerMailId || ''} onChange={(e) => { updateDealer('service.zonalPlannerMailId', e.target.value) }} placeholder="Zonal Planner Email" />
                    {submitted && typeof dealer.service?.zonalPlannerMailId === 'string' && !checkValidMailID(dealer.service?.zonalPlannerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>
                <div className="field">
                    <label htmlFor="regionalManagerName"> Regional Manager Employee Name</label>
                    <InputText value={dealer.service?.regionalManagerName || ''} onChange={(e) => { updateDealer('service.regionalManagerName', e.target.value) }} placeholder="Regional Manager Name" />
                </div>
                <div className="field">
                    <label htmlFor="regionalManagerMailId"> Regional Manager Email</label>
                    <InputText value={dealer.service?.regionalManagerMailId || ''} onChange={(e) => { updateDealer('service.regionalManagerMailId', e.target.value) }} placeholder="Regional Manager Email" />
                    {submitted && typeof dealer.service?.regionalManagerMailId === 'string' && !checkValidMailID(dealer.service?.regionalManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>

                <label className='fw-6 fs-18 clr-navy mb-2'>Sales Details</label>
                <div className="field">
                    <label htmlFor="salesManagerName">Sales Area Manager Employee Name</label>
                    <InputText value={dealer.sales?.areaManagerName || ''} onChange={(e) => { updateDealer('sales.areaManagerName', e.target.value) }} placeholder="Sales Area Manager Name" />
                </div>
                <div className="field">
                    <label htmlFor="salesManagerMailId">Sales Area Manager Employee Email</label>
                    <InputText value={dealer.sales?.areaManagerMailId || ''} onChange={(e) => { updateDealer('sales.areaManagerMailId', e.target.value) }} placeholder="Sales Area Manager Email" />
                    {submitted && typeof dealer.sales?.areaManagerMailId === 'string' && !checkValidMailID(dealer.sales?.areaManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>
                <div className="field">
                    <label htmlFor="areaManagerName"> Zonal Planner Employee Name</label>
                    <InputText value={dealer.sales?.zonalPlannerName || ''} onChange={(e) => { updateDealer('sales.zonalPlannerName', e.target.value) }} placeholder="Zonal Planner Name" />

                </div>
                <div className="field">
                    <label htmlFor="areaManagerMailId"> Zonal Planner Employee Email</label>
                    <InputText value={dealer.sales?.zonalPlannerMailId || ''} onChange={(e) => { updateDealer('sales.zonalPlannerMailId', e.target.value) }} placeholder="Zonal Planner Email" />
                    {submitted && typeof dealer.sales?.zonalPlannerMailId === 'string' && !checkValidMailID(dealer.service?.zonalPlannerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>
                <div className="field">
                    <label htmlFor="regionalSalesManagerName">Sales Regional Manager Employee Name</label>
                    <InputText value={dealer.sales?.regionalManagerName || ''} onChange={(e) => { updateDealer('sales.regionalManagerName', e.target.value) }} placeholder="Sales Regional Manager Name" />
                </div>
                <div className="field">
                    <label htmlFor="regionalSalesManagerMailId">Sales Regional Manager Employee Email</label>
                    <InputText value={dealer.sales?.regionalManagerMailId || ''} onChange={(e) => { updateDealer('sales.regionalManagerMailId', e.target.value) }} placeholder="Sales Regional Manager Email" />
                    {submitted && typeof dealer.sales?.regionalManagerMailId === 'string' && !checkValidMailID(dealer.service?.regionalManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>

                <label className='fw-6 fs-18 clr-navy mb-2'>APS Details</label>
                <div className="field">
                    <label htmlFor="apsManagerName"> Area Parts Manager APS Employee Name</label>
                    <InputText value={dealer.aps?.areaManagerName || ''} onChange={(e) => { updateDealer('aps.areaManagerName', e.target.value) }} placeholder="APS Area Manager Name" />
                </div>
                <div className="field">
                    <label htmlFor="apsManagerMailId"> Area Parts Manager APS Employee Email</label>
                    <InputText value={dealer.aps?.areaManagerMailId || ''} onChange={(e) => { updateDealer('aps.areaManagerMailId', e.target.value) }} placeholder="APS Area Manager Email" />
                    {submitted && typeof dealer.aps?.areaManagerMailId === 'string' && !checkValidMailID(dealer.aps?.areaManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>
                <div className="field">
                    <label htmlFor="apsManagerName"> Regional Parts Manager APS Employee Name</label>
                    <InputText value={dealer.aps?.regionalManagerName || ''} onChange={(e) => { updateDealer('aps.regionalManagerName', e.target.value) }} placeholder="APS Area Manager Name" />
                </div>
                <div className="field">
                    <label htmlFor="apsManagerMailId"> Regional Parts Manager APS Employee Email</label>
                    <InputText value={dealer.aps?.regionalManagerMailId || ''} onChange={(e) => { updateDealer('aps.regionalManagerMailId', e.target.value) }} placeholder="APS Area Manager Email" />
                    {submitted && typeof dealer.aps?.regionalManagerMailId === 'string' && !checkValidMailID(dealer.aps?.regionalManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>
                <div className="field">
                    <label htmlFor="hoPlannerName">HO Parts Planner Employee Name</label>
                    <InputText value={dealer.aps?.hoPlannerName || ''} onChange={(e) => { updateDealer('aps.hoPlannerName', e.target.value) }} placeholder="HO Planner Name" />
                </div>
                <div className="field">
                    <label htmlFor="hoPlannerMailId">HO Parts Planner Employee Email</label>
                    <InputText value={dealer.aps?.hoPlannerMailId || ''} onChange={(e) => { updateDealer('aps.hoPlannerMailId', e.target.value) }} placeholder="HO Planner Email" />
                    {submitted && typeof dealer.aps?.hoPlannerMailId === 'string' && !checkValidMailID(dealer.aps?.hoPlannerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>

                <label className='fw-6 fs-18 clr-navy mb-2'>AO Details</label>
                <div className="field">
                    <label htmlFor="areaCommercialManagerName">AO Area Commercial Manager Employee Name</label>
                    <InputText value={dealer.ao?.areaCommercialManagerName || ''} onChange={(e) => { updateDealer('ao.areaCommercialManagerName', e.target.value) }} placeholder="AO Area Commercial Manager Name" />
                </div>
                <div className="field">
                    <label htmlFor="areaCommercialManagerMailId">AO Area Commercial Manager Employee Email</label>
                    <InputText value={dealer.ao?.areaCommercialManagerMailId || ''} onChange={(e) => { updateDealer('ao.areaCommercialManagerMailId', e.target.value) }} placeholder="AO Area Commercial Manager Email" />
                    {submitted && typeof dealer.ao?.areaCommercialManagerMailId === 'string' && !checkValidMailID(dealer.aps?.areaCommercialManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>
                <div className="field">
                    <label htmlFor="regionalCommercialManagerName">AO Regional Commercial Manager Employee Name</label>
                    <InputText value={dealer.ao?.regionalCommercialManagerName || ''} onChange={(e) => { updateDealer('ao.regionalCommercialManagerName', e.target.value) }} placeholder="AO Regional Commercial Manager Name" />
                </div>
                <div className="field">
                    <label htmlFor="regionalCommercialManagerMailId">AO Regional Commercial Manager Employee Email</label>
                    <InputText value={dealer.ao?.regionalCommercialManagerMailId || ''} onChange={(e) => { updateDealer('ao.regionalCommercialManagerMailId', e.target.value) }} placeholder="AO Regional Commercial Manager Email" />
                    {submitted && typeof dealer.ao?.regionalCommercialManagerMailId === 'string' && !checkValidMailID(dealer.ao?.regionalCommercialManagerMailId?.trim(), 1) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                </div>




            </Dialog>
            <Dialog visible={importdialog} style={{ width: '85%' }} onHide={() => { setImportDialog(false) }} header="Dealer Import Validation Report" >



                <div className='col-12 flex justify-content-end'>
                    <Button style={{ width: 200 }} label="Download Report" className="mr-2" onClick={() => { downloadReport([...importentries.invalidRows, ...importentries.validRows]) }} />
                    {importentries?.actualRows?.length && <Button style={{ width: 200 }} label="Upload Entries" onClick={() => { uploadDealer(importentries.actualRows) }} />}
                </div>

                <TabView
                    activeIndex={0}

                    panelContainerStyle={{ height: "calc(100vh - 200px)" }}
                >
                    {importentries?.validRows?.length && <TabPanel
                        header={
                            `Valid Dealer Entries ${importentries?.validRows?.length}`
                        }
                    >
                        <DataTable className='h-max' value={importentries?.validRows} scrollable >
                            {excelHeader.map((x) =>
                                (<Column header={x} field={x} />)
                            )}
                        </DataTable>

                    </TabPanel>}

                    {importentries?.invalidRows?.length && <TabPanel
                        header={
                            `Valid Dealer Entries ${importentries?.invalidRows?.length}`
                        }
                    >
                        <DataTable className='h-max' value={importentries?.invalidRows} scrollable >
                            {[...excelHeader, 'Remarks'].map((x) =>
                                (<Column header={x} field={x} />)
                            )}
                        </DataTable>

                    </TabPanel>}
                </TabView>

            </Dialog>
        </div >
    )
}

export default AddClientDealerTVS



