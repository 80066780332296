import React, { useState, useEffect } from "react";

import { Tab, Row, Col, Nav, Table, Form, Modal, Card } from 'react-bootstrap';

//import {_utilities} from '../SupplierScreen';

import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import Swal from "sweetalert2";
import moment from 'moment'
import questionary from './assessmentform'
import { InputTextarea } from 'primereact/inputtextarea'
import { RadioButton } from "primereact/radiobutton";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { AccordionTab, Accordion } from "primereact/accordion";
import { Tag } from "primereact/tag";




const SupplierPanel = ({ readOnly, users, auditId, updateData, vendorCode, id, submittedDate, closeModal }) => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const [data, setData] = useState({ data1: [], id: null })
    const [formId, setFormId] = useState(null)
    const [assessmentsection, setAssessmentSection] = useState([])
    const [subsectiondata, setSubSectionData] = useState([])
    const [selectedsubsection2, setSelectedSubSection2] = useState(null)
    const [steps, setSteps] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const forceUpdate = useForceUpdate()
    const [headerValues, setHeaderValues] = useState({});
    const [files, setFiles] = useState([]);
    const [activeQuestionId, setActiveQuestionId] = useState(null);
    const [commentText, setCommentText] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [selectSection, setSelectSection] = useState([])
    const [allCompleted, setAllCompleted] = useState(false)
    const [grandTotalScore, setGrandTotalScore] = useState(0);
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);
    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }]




    const [activeId, setActiveId] = useState(1); // State to hold the active framework ID
    const [labelColor, setLabelColor] = useState("#007bff"); // Default label color (black)
    const [result, setResult] = useState(null)
    const [supplierId, setSupplierId] = useState(null)
    const [submitted, setSubmitted] = useState(null)

    const [highlightedQuestion, setHighlightedQuestion] = useState(null);
    const handleFileChange = (file) => {

        setFiles(file)

    }

    const handleComment = (question, questionIndex, sectionId, subsection1Id, subsection2Id) => {

        setSelectedQuestion({
            ...question,
            questionIndex,
            sectionId,
            subsection1Id,
            subsection2Id,
        });
        setComment(question.comment || '');
        setShowModal(true)
    }
    const uploadFilesApi = async (file, item, event) => {

        setSelectedQuestion(item)
        let promise = new Promise((resolve, rej) => {
            if (file.size <= 10000000) {
                let formData = new FormData();
                formData.append("file", file);
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                    mode: "no-cors",
                }).then((res) => {

                    if (res.status === 200) {
                        resolve({
                            url: API.Docs + res.data.files[0].originalname,
                            name: res.data.files[0].originalname,
                            size: res.data.files[0].size,
                        });
                    } else {
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });

        return promise;
    };
    // const handleSaveComment = () => {
    //     let index = data.data1.findIndex(i => i.name === selectedQuestion?.name)
    //     if (index !== -1) {
    //         data.data1[index].comment = comment
    //         setComment('')
    //         setShowModal(false)
    //         forceUpdate()
    //     }
    // };
    const handleSaveComment = () => {
        if (!selectedQuestion) return; // If somehow nothing selected

        const { sectionId, subsection1Id, subsection2Id, questionIndex } = selectedQuestion;

        const updated = assessmentsection.map((sectionItem) => {
            if (sectionItem.id !== sectionId) return sectionItem;
            return {
                ...sectionItem,
                assessmentSubSection1s: sectionItem.assessmentSubSection1s.map((s1Item) => {
                    if (s1Item.id !== subsection1Id) return s1Item;
                    return {
                        ...s1Item,
                        assessmentSubSection2s: s1Item.assessmentSubSection2s.map((s2Item) => {
                            if (s2Item.id !== subsection2Id) return s2Item;

                            // Parse data1
                            const parsedData = JSON.parse(s2Item.form.data1);

                            // Grab the specific question from parsedData
                            const questionToUpdate = { ...parsedData[questionIndex] };
                            // Update its comment
                            questionToUpdate.comment = comment;

                            // Put it back
                            parsedData[questionIndex] = questionToUpdate;

                            // Return the updated sub-subsection
                            return {
                                ...s2Item,
                                form: {
                                    ...s2Item.form,
                                    data1: JSON.stringify(parsedData), // Overwrite data1 with updated questions
                                },
                            };
                        }),
                    };
                }),
            };
        });

        // Set the new array in state
        setAssessmentSection(updated);

        // Optionally, if you want to keep local `selectSection` in sync, re-find the updated subsection:
        const newSection = updated.find((s) => s.id === sectionId);
        const newSubSection1 = newSection.assessmentSubSection1s.find((x) => x.id === subsection1Id);
        const newSubSection2 = newSubSection1.assessmentSubSection2s.find((x) => x.id === subsection2Id);
        setSelectSection(newSubSection2);

        // Close the dialog
        setShowModal(false);
    };

    const handleAttachment = (file, ques) => {
        let loc = JSON.parse(JSON.stringify(data))
        let index = loc.data1.findIndex(i => i.name === ques?.name)
        if (index !== -1) {
            loc.data1[index].attachments = [file]
            setData(loc)
            forceUpdate()
        }

    };
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');

    const handleOpenModal = () => { setShowModal(true) };
    const handleCloseModal = () => setShowModal(false);



    useEffect(() => {
        const promise0 = APIServices.post(API.sectionData_UP, { assignmentId: auditId.id })
        console.log(auditId)
        Promise.all([promise0]).then((values) => {
            setResult(values[0]?.data?.result)

            if (values[0]?.data?.result === 2) {
                setSubmitted(values[0]?.data?.type === 1 ? true : false)
                setSupplierId(values[0]?.data?.id)
                setGrandTotalScore(values[0]?.data?.supplierMSIScore || 0)
            }

            if (values[0]?.data?.response) {
                //  setSteps(values[0]?.data.sort((a, b) => { return a.order - b.order }, 0).map(i => ({ label: i.title, id: i.id })))
                setAssessmentSection(JSON.parse(values[0]?.data?.response).sort((a, b) => { return a.order - b.order }, 0))
                console.log(JSON.parse(values[0]?.data?.response).sort((a, b) => { return a.order - b.order }, 0))
            } else {
                setSteps([])
                setAssessmentSection([])
            }

        })
    }, [auditId])





    const handleStepClick = (sec, index) => {


        setActiveIndex(index)
        setSubSectionData(assessmentsection.find(i => i.id === sec.id))
    };

    const [expandedItems, setExpandedItems] = useState([]); // Track expanded items
    const [expandAll, setExpandAll] = useState(false); // State to toggle expand all items




    const calculateSum = (data1) => {
        let sum = 0;

        try {
            const parsedData = JSON.parse(data1); // Parse the data1 JSON string
            parsedData.forEach((item) => {
                if (item.type === "radio-group") {
                    item.values.forEach((value) => {
                        if (value.selected) {
                            const numericValue = parseFloat(value.value);
                            if (!isNaN(numericValue)) {
                                sum += numericValue;
                            }
                        }
                    });
                }
            });
        } catch (error) {
            console.error("Error parsing data1:", error);
        }

        return sum;
    };

    /**
 * Checks if all mandatory questions in parsedData are answered.
 * Extend the logic for attachments, comments, etc., if needed.
 */
    const checkAllQuestionsAnswered = (parsedData) => {

        console.log(parsedData)
        return parsedData.every((question) => {
            // If question is NOT mandatory, skip checking it
            // if (!question.mandatory) return true;

            // For mandatory questions:
            if (question.type === "radio-group" && question.values) {
                // True if at least one value is selected
                return question.values.some((v) => v.selected);
            }

            if (question.type === "textarea") {
                if (!question.value?.trim()) {
                    // Textarea is empty
                    return false;
                }
            }

            // 3) Handle select
            if (question.type === "select") {
                if (!question.value) {
                    // Nothing was selected
                    return false;
                }
            }

            // For other question types, add your own checks
            return true;
        });
    }


    const onRadioButtonSelected = (
        question,
        cbind,
        auditId,
        section,
        subsection1,
        selectedsubsection2,
        formId,
        index
    ) => {
        const updatedAssessmentSection = assessmentsection.map((sectionItem) => {
            if (sectionItem.id !== section) return sectionItem;

            const updatedSubSection1s = sectionItem.assessmentSubSection1s.map(
                (subsection1Item) => {
                    if (subsection1Item.id !== subsection1) return subsection1Item;

                    // Update *all* subSection2
                    const updatedSubSection2s = subsection1Item.assessmentSubSection2s.map(
                        (subsection2Item) => {
                            if (subsection2Item.id !== selectedsubsection2) return subsection2Item;

                            const parsedData = JSON.parse(subsection2Item.form.data1);
                            const updatedQuestion = { ...parsedData[index] };

                            if (updatedQuestion && updatedQuestion.values) {
                                updatedQuestion.values = updatedQuestion.values.map((value, idx) => ({
                                    ...value,
                                    selected: idx === cbind,
                                }));
                                updatedQuestion.modified_by = vendorCode.id;
                                updatedQuestion.modified_on = DateTime.utc();
                            }

                            parsedData[index] = updatedQuestion;
                            const newScore = calculateSum(JSON.stringify(parsedData));
                            const allAnswered = checkAllQuestionsAnswered(parsedData);

                            return {
                                ...subsection2Item,
                                completed: allAnswered,
                                form: {
                                    ...subsection2Item.form,
                                    data1: JSON.stringify(parsedData),
                                    score: newScore,
                                },
                            };
                        }
                    );

                    // Summation for subSection1
                    const totalScoreForSubsection1 = updatedSubSection2s.reduce((acc, sub2) => {
                        return acc + (sub2.form?.score || 0);
                    }, 0);

                    const allSub2Completed =
                        updatedSubSection2s.length > 0 &&
                        updatedSubSection2s.every((sub2) => sub2.completed);

                    return {
                        ...subsection1Item,
                        assessmentSubSection2s: updatedSubSection2s,
                        totalScore: totalScoreForSubsection1,
                        totalCompleted: allSub2Completed,
                    };
                }
            );

            // Now check if entire Section is completed
            const allSub1Completed =
                updatedSubSection1s.length > 0 &&
                updatedSubSection1s.every((sub1) => sub1.totalCompleted);

            // 5) Compute total score for the entire Section
            const totalScoreForSection = updatedSubSection1s.reduce((acc, sub1) => {
                return acc + (sub1.totalScore || 0);
            }, 0);

            return {
                ...sectionItem,
                assessmentSubSection1s: updatedSubSection1s,
                sectionTotalScore: totalScoreForSection,
                completed: allSub1Completed, // entire section
            };
        });

        setAssessmentSection(updatedAssessmentSection);

        const overallScore = updatedAssessmentSection.reduce((acc, sec) => {
            return acc + (sec.sectionTotalScore || 0);
        }, 0);

        // 2) Save it in your separate state
        setGrandTotalScore(overallScore);

        const isEverythingCompleted =
            updatedAssessmentSection.length > 0 &&
            updatedAssessmentSection.every((sec) => sec.completed === true);

        // 2) Store that in local state
        setAllCompleted(isEverythingCompleted);

        // -- Re-find the updated SubSection2 for `selectSection` --
        const newSectionItem = updatedAssessmentSection.find((s) => s.id === section);
        const newSubSection1Item = newSectionItem.assessmentSubSection1s.find(
            (s1) => s1.id === subsection1
        );
        const newSubSection2Item = newSubSection1Item.assessmentSubSection2s.find(
            (s2) => s2.id === selectedsubsection2
        );

        // Update local state reference (if you're using `selectSection` in rendering):
        setSelectSection(newSubSection2Item);

        // Optionally force a re-render:
        forceUpdate();

        console.log("Updated Assessment Section:", updatedAssessmentSection);
    };



    const getDate = (date, format) => {
        if (!date) {
            return 'Not Set'
        }
        if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const onChangeDropwdown = (newValue,
        questionIndex,
        sectionId,
        subsection1Id,
        subsection2Id) => {
        const updatedAssessmentSection = assessmentsection.map((sec) => {
            // Only update the matching section
            if (sec.id !== sectionId) return sec;

            return {
                ...sec,
                assessmentSubSection1s: sec.assessmentSubSection1s.map((sub1) => {
                    if (sub1.id !== subsection1Id) return sub1;

                    // 2) Update SubSection2 array
                    const updatedSub2s = sub1.assessmentSubSection2s.map((sub2) => {
                        if (sub2.id !== subsection2Id) return sub2;

                        // 3) Parse the questions in data1
                        const parsedData = JSON.parse(sub2.form.data1);
                        const updatedQuestion = { ...parsedData[questionIndex] };

                        // 4) Set the new text value + metadata
                        updatedQuestion.value = newValue;
                        updatedQuestion.modified_by = vendorCode.id;
                        updatedQuestion.modified_on = DateTime.utc().toFormat('dd-MM-yyyy');

                        // 5) Put the updated question back
                        parsedData[questionIndex] = updatedQuestion;

                        return {
                            ...sub2,
                            form: {
                                ...sub2.form,
                                data1: JSON.stringify(parsedData), // Overwrite data1 with updated questions
                            },
                        };
                    });

                    return {
                        ...sub1,
                        assessmentSubSection2s: updatedSub2s,
                    };
                }),
            };
        });

        // 6) Update your state
        setAssessmentSection(updatedAssessmentSection);

        // (Optional) Re-find the updated sub2 item if you’re using selectSection in your UI
        const newSection = updatedAssessmentSection.find((s) => s.id === sectionId);
        const newSubSection1 = newSection.assessmentSubSection1s.find(
            (s1) => s1.id === subsection1Id
        );
        const newSubSection2 = newSubSection1.assessmentSubSection2s.find(
            (s2) => s2.id === subsection2Id
        );
        setSelectSection(newSubSection2);

        // Force a re-render if needed
        forceUpdate();
        forceUpdate()
    }

    // const onTextareaChange = (
    //     newValue,
    //     questionIndex,
    //     sectionId,
    //     subsection1Id,
    //     subsection2Id
    // ) => {
    //     // 1) Build an updated copy of assessmentsection
    //     const updatedAssessmentSection = assessmentsection.map((sec) => {
    //         // Only update the matching section
    //         if (sec.id !== sectionId) return sec;

    //         return {
    //             ...sec,
    //             assessmentSubSection1s: sec.assessmentSubSection1s.map((sub1) => {
    //                 if (sub1.id !== subsection1Id) return sub1;

    //                 // 2) Update SubSection2 array
    //                 const updatedSub2s = sub1.assessmentSubSection2s.map((sub2) => {
    //                     if (sub2.id !== subsection2Id) return sub2;

    //                     // 3) Parse the questions in data1
    //                     const parsedData = JSON.parse(sub2.form.data1);
    //                     const updatedQuestion = { ...parsedData[questionIndex] };

    //                     // 4) Set the new text value + metadata
    //                     updatedQuestion.value = newValue;
    //                     updatedQuestion.modified_by = vendorCode.id;
    //                     updatedQuestion.modified_on = DateTime.utc().toFormat('dd-MM-yyyy');

    //                     // 5) Put the updated question back
    //                     parsedData[questionIndex] = updatedQuestion;
    //                     const allAnswered = checkAllQuestionsAnswered(parsedData);

    //                     return {
    //                         ...sub2,
    //                         completed: allAnswered,
    //                         form: {
    //                             ...sub2.form,
    //                             data1: JSON.stringify(parsedData), // Overwrite data1 with updated questions
    //                         },
    //                     };
    //                 });

    //                 return {
    //                     ...sub1,
    //                     assessmentSubSection2s: updatedSub2s,
    //                 };
    //             }),
    //         };
    //     });

    //     // 6) Update your state
    //     setAssessmentSection(updatedAssessmentSection);

    //     // (Optional) Re-find the updated sub2 item if you’re using selectSection in your UI
    //     const newSection = updatedAssessmentSection.find((s) => s.id === sectionId);
    //     const newSubSection1 = newSection.assessmentSubSection1s.find(
    //         (s1) => s1.id === subsection1Id
    //     );
    //     const newSubSection2 = newSubSection1.assessmentSubSection2s.find(
    //         (s2) => s2.id === subsection2Id
    //     );
    //     setSelectSection(newSubSection2);

    //     // Force a re-render if needed
    //     forceUpdate();
    // };


    const onTextareaChange = (
        newValue,
        questionIndex,
        sectionId,
        subsection1Id,
        subsection2Id
    ) => {
        const updatedAssessmentSection = assessmentsection.map((sectionItem) => {
            // Only update the matching section
            if (sectionItem.id !== sectionId) return sectionItem;

            // Update all subSection1 items
            const updatedSubSection1s = sectionItem.assessmentSubSection1s.map(
                (subsection1Item) => {
                    // Only update the matching subsection1
                    if (subsection1Item.id !== subsection1Id) return subsection1Item;

                    // Update all subSection2 items
                    const updatedSubSection2s =
                        subsection1Item.assessmentSubSection2s.map((subsection2Item) => {
                            // Only update the matching subsection2
                            if (subsection2Item.id !== subsection2Id) {
                                return subsection2Item;
                            }

                            // 1) Parse the questions in data1
                            const parsedData = JSON.parse(subsection2Item.form.data1);

                            // 2) Update the specific question
                            const updatedQuestion = { ...parsedData[questionIndex] };
                            updatedQuestion.value = newValue;
                            updatedQuestion.modified_by = vendorCode.id;
                            updatedQuestion.modified_on = DateTime.utc().toFormat("dd-MM-yyyy");

                            // 3) Put the updated question back
                            parsedData[questionIndex] = updatedQuestion;

                            // 4) Recompute the score for this subSection2
                            //   const newScore = calculateSum(JSON.stringify(parsedData));

                            // 5) Check if all questions in this subSection2 are answered
                            const allAnswered = checkAllQuestionsAnswered(parsedData);

                            return {
                                ...subsection2Item,
                                completed: allAnswered,
                                form: {
                                    ...subsection2Item.form,
                                    data1: JSON.stringify(parsedData),

                                },
                            };
                        });

                    // 6) Compute total score for the entire subsection1
                    const totalScoreForSubsection1 = updatedSubSection2s.reduce(
                        (acc, sub2) => acc + (sub2.form?.score || 0),
                        0
                    );

                    // 7) Check if ALL subSection2 are completed
                    const allSub2Completed =
                        updatedSubSection2s.length > 0 &&
                        updatedSubSection2s.every((sub2) => sub2.completed);

                    return {
                        ...subsection1Item,
                        assessmentSubSection2s: updatedSubSection2s,

                        totalCompleted: allSub2Completed,
                    };
                }
            );

            // 8) Check if entire Section is completed
            const allSub1Completed =
                updatedSubSection1s.length > 0 &&
                updatedSubSection1s.every((sub1) => sub1.totalCompleted);

            // 9) Compute total score for the entire Section


            return {
                ...sectionItem,
                assessmentSubSection1s: updatedSubSection1s,

                completed: allSub1Completed, // entire section
            };
        });

        // 10) Update assessmentsection in state
        setAssessmentSection(updatedAssessmentSection);

        // 11) Compute overall score across all sections

        // 12) Check if ALL sections are completed
        const isEverythingCompleted =
            updatedAssessmentSection.length > 0 &&
            updatedAssessmentSection.every((sec) => sec.completed === true);
        setAllCompleted(isEverythingCompleted);

        // 13) (Optional) Re-find and set the updated subsection2 in local state if needed
        const newSection = updatedAssessmentSection.find((s) => s.id === sectionId);
        const newSubSection1 = newSection.assessmentSubSection1s.find(
            (s1) => s1.id === subsection1Id
        );
        const newSubSection2 = newSubSection1.assessmentSubSection2s.find(
            (s2) => s2.id === subsection2Id
        );
        setSelectSection(newSubSection2);

        // 14) Force a re-render if you want
        forceUpdate();

        console.log("Updated Assessment Section:", updatedAssessmentSection);
    };


    const onFileUpload = async (
        uploadedFile,
        questionIndex,
        sectionId,
        subsection1Id,
        subsection2Id
    ) => {
        try {
            // 1) Call your API to upload the file
            const result = await uploadFilesApi(uploadedFile);
            if (!result) return; // If something went wrong

            // 2) Build an updated copy of assessmentsection
            const updatedAssessmentSection = assessmentsection.map((sec) => {
                if (sec.id !== sectionId) return sec;

                return {
                    ...sec,
                    assessmentSubSection1s: sec.assessmentSubSection1s.map((sub1) => {
                        if (sub1.id !== subsection1Id) return sub1;

                        const updatedSub2s = sub1.assessmentSubSection2s.map((sub2) => {
                            if (sub2.id !== subsection2Id) return sub2;

                            // Parse data1 to find correct question
                            const parsedData = JSON.parse(sub2.form.data1);
                            const updatedQuestion = { ...parsedData[questionIndex] };

                            // 3) Update question attachments
                            // If you only allow 1 file, replace the array
                            // If you allow multiple files, push to the array
                            updatedQuestion.attachments = [
                                ...(updatedQuestion.attachments || []),
                                {
                                    name: result.name,
                                    url: result.url,
                                    size: result.size,
                                },
                            ];

                            // If there's any mandatory logic or timestamps, do it here
                            updatedQuestion.modified_by = vendorCode.id;
                            updatedQuestion.modified_on = DateTime.utc();

                            parsedData[questionIndex] = updatedQuestion;

                            return {
                                ...sub2,
                                form: {
                                    ...sub2.form,
                                    data1: JSON.stringify(parsedData), // Overwrite with updated JSON
                                },
                            };
                        });
                        return {
                            ...sub1,
                            assessmentSubSection2s: updatedSub2s,
                        };
                    }),
                };
            });

            // 4) Update state
            setAssessmentSection(updatedAssessmentSection);

            // 5) Optionally re-find & set selectSection if you rely on that
            const newSection = updatedAssessmentSection.find((s) => s.id === sectionId);
            const newSubSection1 = newSection.assessmentSubSection1s.find(
                (s1) => s1.id === subsection1Id
            );
            const newSubSection2 = newSubSection1.assessmentSubSection2s.find(
                (s2) => s2.id === subsection2Id
            );
            setSelectSection(newSubSection2);

            forceUpdate();
        } catch (error) {
            console.error("File upload failed: ", error);
        }
    };



    /**
 * Validate all questions in the assessmentsection structure.
 * Return { valid: boolean, message: string } so we can show a message if invalid.
 */
    function validateAssessment(assessmentsection) {
        for (const sectionItem of assessmentsection) {
            const sectionId = sectionItem.id;

            for (const sub1Item of sectionItem.assessmentSubSection1s || []) {
                const subsection1Id = sub1Item.id;

                for (const sub2Item of sub1Item.assessmentSubSection2s || []) {
                    const subsection2Id = sub2Item.id;

                    let parsedQuestions = [];
                    try {
                        parsedQuestions = JSON.parse(sub2Item.form?.data1 || "[]");
                    } catch (err) {
                        return {
                            valid: false,
                            message: "Invalid data structure found.",
                            location: { sectionId, subsection1Id, subsection2Id, questionIndex: 0 }
                        };
                    }

                    for (let questionIndex = 0; questionIndex < parsedQuestions.length; questionIndex++) {
                        const question = parsedQuestions[questionIndex];

                        // 1) Check question.required (or "required": true, or whatever your field is)

                        // Check based on question type
                        if (question.type === "radio-group") {
                            const selectedValue = question.values?.find((v) => v.selected)?.label;
                            if (!selectedValue) {
                                return {
                                    valid: false,
                                    message: `Please select a response for "${question.label}".`,
                                    location: { sectionId, subsection1Id, subsection2Id, questionIndex }
                                };
                            }

                        }
                        if (question.type === "textarea") {
                            if (!question.value?.trim()) {
                                console.log(question.value, question)

                                return {
                                    valid: false,
                                    message: `Please enter text for "${question.label}".`,
                                    location: { sectionId, subsection1Id, subsection2Id, questionIndex }
                                };
                            }
                        }
                        if (question.type === "select") {
                            if (!question.value) {
                                return {
                                    valid: false,
                                    message: `Please select a value for "${question.label}".`,
                                    location: { sectionId, subsection1Id, subsection2Id, questionIndex }
                                };
                            }
                        }



                    }
                }
            }
        }

        // If we never fail, it's valid:
        return { valid: true, message: "All required fields are filled.", location: null };
    }
    const checkQuestionary = (data) => {
        let loc = JSON.parse(data)
        if (loc.length) {
            return loc.every((item) => { return item.type === 'select' ? item.value : item.type === 'radio-group' ? item?.values?.some((v) => v?.selected) : item.type === 'textarea' ? item.value && item.value?.trim()?.length : true })
        } else {
            return false
        }
    }
    const checkSubSection1 = (data) => {
        if (data?.assessmentSubSection2s?.length) {
            return data?.assessmentSubSection2s.every((item) => { return checkQuestionary(item?.form?.data1 || []) })
        } else {
            return false
        }
    }

    const renderResponse = (question, index, auditId, section, subsection1, selectedsubsection2, formId) => {

        const highlightThis =
            highlightedQuestion &&
            highlightedQuestion.sectionId === section &&
            highlightedQuestion.subsection1Id === subsection1 &&
            highlightedQuestion.subsection2Id === selectedsubsection2 &&
            highlightedQuestion.questionIndex === index;
        // console.log(question)
        return (
            <>  <div key={question.id + Math.random()} className='questDisp col-12 grid m-0 p-0' style={{
                // If we should highlight, show a red border (or any style you prefer)
                border: highlightThis ? "2px solid red" : "none",
                padding: highlightThis ? "8px" : "0"
            }}>
                <div className="col-10 fs-16 fw-4">
                    <p style={{ color: question.isDedicated ? "red" : '#374151' }}>{question.label}</p>

                    {question.type === 'radio-group' ? (
                        <div className='grid m-0 p-0'>
                            {question.values.map((cb, cbind) => {
                                return (
                                    <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                        <RadioButton
                                            inputId={"rg" + index + cbind}
                                            name={cb.label}
                                            value={cb.value}
                                            onChange={(e) =>
                                                onRadioButtonSelected(
                                                    question,
                                                    cbind,
                                                    auditId,
                                                    section,
                                                    subsection1,
                                                    selectedsubsection2,
                                                    formId,
                                                    index
                                                )
                                            }
                                            checked={cb.selected || false} // Ensure `checked` reflects the updated state
                                        />
                                        <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                    </div>
                                )
                            })}
                        </div>

                    ) :
                        question.type === 'select' ? <Dropdown placeholder={question.placeholder} options={question.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={question.value} onChange={(e) => {
                            onChangeDropwdown(e.value,        // new textarea text
                                index,                 // question index
                                section,               // section id
                                subsection1,           // subsection1 id
                                selectedsubsection2)
                        }} />



                            : question.type === 'textarea' ? (
                                <InputTextarea
                                    placeholder="Enter your response here"
                                    value={question.value}
                                    style={{ width: '100%', height: 120, overflow: 'auto' }}
                                    onChange={(e) => {
                                        onTextareaChange(
                                            e.target.value,        // new textarea text
                                            index,                 // question index
                                            section,               // section id
                                            subsection1,           // subsection1 id
                                            selectedsubsection2
                                        );
                                    }}
                                />


                            ) : null}
                </div>
                <div className="col-2 flex  justify-content-between" style={{ flexDirection: 'column' }}>
                    {question.isAttachmentMandatory &&
                        <>{(!question.attachments || (question.attachments && question.attachments.length === 0)) ?
                            <div>

                                <FileUpload
                                    name="demo[]"
                                    auto
                                    customUpload
                                    mode="basic"
                                    className="custom-upload-image"
                                    multiple={false}
                                    accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
                                    chooseOptions={{ icon: 'pi pi-fw pi-upload fs-14 fw-6', label: 'Upload Attachment', className: 'custom-choose-btn' }}
                                    uploadHandler={(e) => {
                                        const file = e.files[0];
                                        onFileUpload(
                                            file,
                                            index,                  // question index
                                            section,                // sectionId
                                            subsection1,            // subsection1Id
                                            selectedsubsection2     // subsection2Id
                                        );
                                    }}
                                />

                            </div> :
                            <div className="clr-navy grid m-0  p-0" > <i className="pi pi-paperclip fs-14 fw-6 mr-2" /> <label style={{ width: '80%' }} className='text-three-dot text-underline cur-pointer' onClick={() => { window.open(API.Docs + question.attachments[0].name) }} >{question.attachments[0].name} </label> </div>
                        }
                        </>
                    }
                    <div style={{ color: '#315975' }} className="" onClick={(e) => { e.stopPropagation(); handleComment(question, index, section, subsection1, selectedsubsection2); }}>
                        <i className="pi pi-comment fs-14 fw-6 mr-2" style={{ marginRight: '5px', cursor: 'pointer' }}></i>
                        <span>{question.comment ? 'Update ' : 'Add '} Comment</span>
                    </div>
                </div>
            </div>
                < hr className="p-1 m-0" />
            </>
        )
    }


    const handleSubmit = () => {
        console.log(assessmentsection)
        const { valid, message, location } = validateAssessment(assessmentsection);

        if (!valid) {
            // Show an error message to the user (Swal, Toast, etc.)
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: message,
            });

            // location = { sectionId, subsection1Id, subsection2Id, questionIndex }
            // 1) Move the Stepper to the correct "section"
            const sectionIndex = assessmentsection.findIndex(
                (sec) => sec.id === location.sectionId
            );
            if (sectionIndex !== -1) {
                setActiveIndex(sectionIndex);  // or whatever function sets your stepper index
            }

            // 2) Expand the correct Accordion tab
            // You can find which sub1 is in the array:
            const sub1Index = assessmentsection[sectionIndex]?.assessmentSubSection1s?.findIndex(
                (s1) => s1.id === location.subsection1Id
            );
            // setActiveAccordionIndex(sub1Index) if you are using that logic

            // 3) Set selected sub-subsection
            const sub2Item = assessmentsection[sectionIndex]?.assessmentSubSection1s?.[sub1Index].assessmentSubSection2s?.find(
                (s2) => s2.id === location.subsection2Id
            );
            if (sub2Item) {
                setSelectedSubSection2(sub2Item.id);
                setSelectSection(sub2Item);
            }

            // 4) Optionally, highlight the question itself
            // For example, store a highlight state that renderResponse checks
            setHighlightedQuestion({
                sectionId: location.sectionId,
                subsection1Id: location.subsection1Id,
                subsection2Id: location.subsection2Id,
                questionIndex: location.questionIndex
            });

            return;
        }

        if (result === 1 && vendorCode?.id && vendorCode?.code) {
            const requestBody = {
                type: 1,
                response: JSON.stringify(assessmentsection),
                created_on: DateTime.utc(), // If you need a timestamp
                modified_on: DateTime.utc(),
                created_by: auditId.vendor.id,
                userProfileId: admin_data.id,
                submitted_on: DateTime.utc(),
                submitted_by: auditId.vendor.id,
                supplierMSIScore: grandTotalScore,
                status: 0,
                vendorCode: auditId.vendor.code,
                vendorId: auditId.vendor.id

            };
            APIServices.post(API.Submit_supplier_response(auditId.id), requestBody)
                .then(response => {
                    closeModal(false)
                    Swal.fire("Success!", "Response Updated successfully.", "success").then(() => {
                        window.location.reload(); // Reloads the page after clicking OK
                    });
                })
                .catch(error => {
                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        } else if (result === 2 && vendorCode?.id && vendorCode.userProfileId) {
            const requestBody = {
                submitted_on: DateTime.utc(),
                submitted_by: auditId.vendor.id,
                response: JSON.stringify(assessmentsection),
                modified_on: DateTime.utc(),
                modified_by: auditId.vendor.id,
                supplierMSIScore: grandTotalScore,
                type: 1,
                status: 0
            };

            APIServices.patch(API.fetch_supplier_data(supplierId), requestBody)
                .then(response => {
                    closeModal(false)

                    Swal.fire("Success!", "Response Updated successfully.", "success")
                })
                .catch(error => {
                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        }
    }

    const handleProgress = () => {

        if (result === 1 && vendorCode?.id && vendorCode?.code) {
            const requestBody = {
                type: 0,
                response: JSON.stringify(assessmentsection),
                created_on: DateTime.utc(), // If you need a timestamp
                modified_on: DateTime.utc(),
                submitted_on: null,
                submitted_by: null,
                created_by: auditId.vendor.id,
                userProfileId: admin_data.id,
                supplierMSIScore: grandTotalScore,
                status: 0,
                vendorCode: auditId.vendor.code,
                vendorId: auditId.vendor.id
            };

            APIServices.post(API.Submit_supplier_response(auditId.id), requestBody)
                .then(response => {
                    setSupplierId(response?.data?.id || null)
                    setResult(response?.data?.id ? 2 : 0)
                    Swal.fire("Success!", "Response Updated successfully.", "success")
                })
                .catch(error => {
                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        } else if (result === 2 && vendorCode?.id && vendorCode?.code) {
            const requestBody = {
                submitted_on: null,
                submitted_by: null,
                response: JSON.stringify(assessmentsection),
                modified_on: DateTime.utc(),
                modified_by: auditId.vendor.id,
                supplierMSIScore: grandTotalScore,
                type: 0
            };

            APIServices.patch(API.fetch_supplier_data(supplierId), requestBody)
                .then(response => {
                    Swal.fire("Success!", "Response Updated successfully.", "success")
                })
                .catch(error => {
                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        }
    };
    const stepss = assessmentsection
        ?.sort((a, b) => a.order - b.order)
        .map((section) => {
            // Validate only this one section by passing an array with a single element [section]
            const { valid } = validateAssessment([section]);
            return {
                label: section.title,
                id: section.id,
                completed: valid, // or use section.completed if you prefer
            };
        }) || [];
    return (
        <>


            <div className="row mb-3" style={{ padding: '10px' }}>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Self-assessment Due Date</p>
                    <p className="obs-content">
                        {getDate(auditId.assessmentEndDate, 'dd MMM yyyy')}
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Self-assessment Submitted Date</p>
                    <p className="obs-content">
                        {auditId?.supplierAssignmentSubmission?.submitted_on ? getDate(auditId?.supplierAssignmentSubmission?.submitted_on, 'dd MMM yyyy') : 'Not Submitted'}
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Status</p>

                    <Tag style={{ width: 'unset' }} className={(!result || result === 1) ? 'status-tag-red' : (result === 3) ? 'status-tag-green' : (result === 1) ? 'status-tag-orange' : 'NA'} >{(!result || result === 1) ? 'Not Started' : (result === 3) ? 'Completed' : (result === 2) ? 'In Progress' : 'NA'}</Tag>

                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Category</p>

                    <p className="obs-content">
                        {categoryList.find(x => x.value === auditId?.vendor?.supplierCategory)?.name || 'NA'}

                    </p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Vendor Code</p>
                    <p className="obs-content"> {auditId?.vendor?.code || '-'}</p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">No. Of Critical Regulatory Gaps Identified</p>
                    <p className="obs-content">
                        0
                    </p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">MSI Self-Assessment Score</p>
                    <p className="obs-content">
                        {grandTotalScore}
                    </p>
                </div>
            </div>

            {console.log(assessmentsection)}

            <Tab.Container id="left-tabs-example" className="mt-3 audit-tab" activeKey={assessmentsection?.[activeIndex]?.id} >
                <Row style={{ marginTop: 20 }}>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        <Stepper
                            steps={stepss}
                            onClickStep={handleStepClick}
                            activeBGColor="green"
                            labelColor={labelColor}
                            activeIndex={activeIndex} // Current active step index
                        />
                    </div>

                    <Col sm={12}>

                        <Tab.Content>
                            {assessmentsection.map((section, index) => {

                                return (<Tab.Pane eventKey={section.id} key={section.id}>
                                    <label className="mb-4" style={{ fontSize: 14, fontWeight: 'bold' }}> Expand each of the sections below, click on the sub-section and provide your responses to all the check points. Where required, attach relevant documents. Please note that check points in red are considered critical questions from TVS Motors perspective and require documentary evidence.</label>

                                    <Accordion activeIndex={activeAccordionIndex}
                                        onTabChange={(e) => {
                                            setActiveAccordionIndex(e.index);
                                            // RESET your sub-subsection selection so you don't see old data
                                            setSelectedSubSection2(null);
                                            setSelectSection(null);
                                        }}>
                                        {section?.assessmentSubSection1s?.sort((a, b) => { return a.order - b.order }, 0).map((subsection1) => {
                                            return (
                                                <AccordionTab key={subsection1.id} header={`${subsection1.title} - ${subsection1.totalScore || 0}`} headerStyle={{ borderLeft: '5px solid ' + (checkSubSection1(subsection1) ? 'green' : '#F59E0B') }} >
                                                    {
                                                        <>
                                                            <Nav variant="pills" className="flex-row custom-nav">
                                                                {subsection1?.assessmentSubSection2s?.map(subsection2 => (
                                                                    <Nav.Item key={subsection2.id}>
                                                                        <Nav.Link eventKey={subsection2.id} style={{ borderLeft: '5px solid ' + (checkQuestionary(subsection2?.form?.data1 || []) ? 'green' : '#F59E0B') }} onClick={(ev) => { setSelectedSubSection2(subsection2.id); setFormId(subsection2?.formId); setSelectSection(subsection2); }}  >
                                                                            {subsection2.title} - {subsection2?.form?.score || 0}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))}
                                                            </Nav>




                                                            {selectedsubsection2 && selectSection && (
                                                                <div>
                                                                    {selectSection.form?.data1 && (
                                                                        <div>
                                                                            {/* Parse and iterate over data1 */}
                                                                            {JSON.parse(selectSection.form.data1).map((question, index) => (
                                                                                <div key={index}>
                                                                                    {renderResponse(
                                                                                        question,
                                                                                        index,
                                                                                        auditId.id,
                                                                                        section.id,
                                                                                        subsection1.id,
                                                                                        selectedsubsection2,
                                                                                        formId
                                                                                    )}


                                                                                </div>


                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}

                                                        </>
                                                    }
                                                </AccordionTab>
                                            )
                                        })
                                        }
                                    </Accordion>
                                </Tab.Pane>)
                            })}
                        </Tab.Content>



                    </Col>


                </Row>
            </Tab.Container>


            {!submitted && assessmentsection?.length && <div className="mt-4" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                {/* Outlined + warning color */}
                <Button
                    label="Save Progress"
                    className=" p-button-warning"
                    onClick={() => handleProgress()}
                />

                {/* <Button
                    label="Save "
                    className=" p-button-success"
                    onClick={() => handleProgress()}
                /> */}
                {/* Outlined + success color */}
                <Button
                    label="Submit"
                    onClick={handleSubmit}
                    disabled={!validateAssessment(assessmentsection).valid}  // Only enabled if everything is completed
                />
            </div>}




            <Dialog
                header={
                    'Add Comment '
                }
                visible={showModal}
                style={{ width: '75%' }}
                onHide={handleCloseModal}
            >
                <div>
                    <InputTextarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        style={{ width: '100%', height: 150, overflow: 'auto' }}
                    />
                    <div className="flex justify-content-end col-12">
                        <Button
                            style={{ width: 110 }}
                            className="mr-2"
                            label="Close"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        />
                        <Button
                            style={{ width: 110 }}
                            label="Save & Exit"
                            onClick={() => handleSaveComment()}
                        />
                    </div>
                </div>
            </Dialog>

        </>
    );
};

const Stepper = ({ steps, onClickStep, labelColor = 'black', activeBGColor = '#6c757d', activeIndex = 0 }) => {

    console.log(steps)

    return (<>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <div style={{ position: 'relative' }}>
                        <div
                            onClick={() => onClickStep && onClickStep(step, index)}
                            style={{
                                display: 'flex',
                                position: 'relative',
                                flexDirection: 'column',
                                zIndex: 1,
                                alignItems: 'center',
                                cursor: activeIndex !== index ? 'pointer' : 'default',
                                textAlign: 'center',
                                width: '170px', // Fixed width to keep all steps aligned
                            }}
                        >
                            <div
                                style={{
                                    //backgroundColor: (step.status === 1 ? activeBGColor : step.status === 0 ? 'rgb(255, 165, 0)' : 'gray'),
                                    backgroundColor: step.completed === undefined
                                        ? 'grey'
                                        : step.completed === true
                                            ? 'green'
                                            : 'rgb(255, 165, 0)',

                                    color: '#fff',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    transition: 'background-color 0.3s ease', // Smooth color transition
                                }}
                            >
                                {index + 1} {/* Display step number starting from 1 */}
                            </div>
                            <div
                                style={{
                                    fontSize: 16,
                                    marginTop: '8px',
                                    fontWeight: activeIndex === index ? 700 : 'normal', // Adjusted for 1-based index
                                    color: activeIndex === index ? labelColor : 'black',
                                    textDecoration: activeIndex === index ? 'underline' : 'none',
                                }}
                            >
                                {step.label}
                            </div>
                        </div>

                        {/* Add line between steps, except for the last step */}
                        {index < steps.length - 1 && (
                            <hr
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    left: '50%',
                                    top: '8px',
                                    width: '100%',
                                    position: 'absolute',
                                    zIndex: 0,
                                }}
                            />
                        )}
                    </div>
                </React.Fragment>
            ))}
        </div>

        <div className='color mb-3 mt-3 d-flex justify-content-end'>
            <div className="d-flex align-items-center me-4">
                <div className="instruction-circle" style={{ backgroundColor: '#28a745' }}></div>
                <span className="ms-2">Finalized</span>
            </div>
            <div className="d-flex align-items-center me-4">
                <div className="instruction-circle" style={{ backgroundColor: '#ffa500' }}></div>
                <span className="ms-2">Drafted</span>
            </div>
            <div className="d-flex align-items-center">
                <div className="instruction-circle" style={{ backgroundColor: '#808080' }}></div>
                <span className="ms-2">No information entered</span>
            </div>
        </div>
    </>);
};

export default SupplierPanel;