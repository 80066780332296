import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const EnvironmentChart = ({ data }) => {
  // Function to filter data based on category
  const filterDataByCategory = (category) =>
    data.filter((entry) => entry.category === category);

  // Function to compute average values and max values for comparison
  const aggregateData = (filteredData, categoryType) => {
    const totals = {
      Environment: 0,
      water_management: 0,
      waste_water_management: 0,
      waste_management: 0,
      energy_management: 0,
    };

    // Set max values, with Wastewater Management having different max values for Sales and Service
    const maxValues =
      categoryType === "Service"
        ? {
            Environment: 10,
            water_management: 10,
            waste_water_management: 5,
            waste_management: 5,
            energy_management: 10,
          }
        : {
            Environment: 10,
            water_management: 10,
            waste_water_management: categoryType === "Sales" ? 0 : 10,
            waste_management: 10,
            energy_management: 10,
          };

    let count = filteredData.length || 1; // Prevent division by zero

    filteredData.forEach((entry) => {
      const environment = isNaN(entry.Environment) ? 0 : entry.Environment;
      const waterManagement = isNaN(entry.water_management)
        ? 0
        : entry.water_management;
      const wasteWaterManagement = isNaN(
        parseFloat(entry.waste_water_management)
      )
        ? 0
        : parseFloat(entry.waste_water_management);
      const wasteManagement = isNaN(entry.waste_management)
        ? 0
        : entry.waste_management;
      const energyManagement = isNaN(entry.energy_management)
        ? 0
        : entry.energy_management;

      totals.Environment += environment;
      totals.water_management += waterManagement;
      totals.waste_water_management += wasteWaterManagement;
      totals.waste_management += wasteManagement;
      totals.energy_management += energyManagement;
    });

    // Helper function to clamp values between 0 and maxValue for graph display
    const clampForGraph = (value) => Math.max(0, value);
    const clampRemaining = (avgValue, maxValue) =>
      Math.max(0, Math.min(maxValue, maxValue - avgValue));

    return [
      {
        category: "Environment",
        avgValue: totals.Environment / count,
        avgValueForGraph: clampForGraph(totals.Environment / count),
        maxValue: maxValues.Environment,
        remainingToMax: clampRemaining(
          totals.Environment / count,
          maxValues.Environment
        ),
      },
      {
        category: "Water Management",
        avgValue: totals.water_management / count,
        avgValueForGraph: clampForGraph(totals.water_management / count),
        maxValue: maxValues.water_management,
        remainingToMax: clampRemaining(
          totals.water_management / count,
          maxValues.water_management
        ),
      },
      {
        category: "Wastewater Management",
        avgValue: totals.waste_water_management / count,
        avgValueForGraph: clampForGraph(totals.waste_water_management / count),
        maxValue: maxValues.waste_water_management,
        remainingToMax: clampRemaining(
          totals.waste_water_management / count,
          maxValues.waste_water_management
        ),
      },
      {
        category: "Waste Management",
        avgValue: totals.waste_management / count,
        avgValueForGraph: clampForGraph(totals.waste_management / count),
        maxValue: maxValues.waste_management,
        remainingToMax: clampRemaining(
          totals.waste_management / count,
          maxValues.waste_management
        ),
      },
      {
        category: "Energy Management",
        avgValue: totals.energy_management / count,
        avgValueForGraph: clampForGraph(totals.energy_management / count),
        maxValue: maxValues.energy_management,
        remainingToMax: clampRemaining(
          totals.energy_management / count,
          maxValues.energy_management
        ),
      },
    ];
  };

  // Aggregating data for both Sales and Service
  const salesData = aggregateData(filterDataByCategory("Sales"), "Sales");
  const serviceData = aggregateData(filterDataByCategory("Service"), "Service");

  // Function to determine Y-Axis domain with a fixed max value (10)
  const getYAxisDomain = () => {
    return [0, 10]; // Fixed Y-Axis domain with a max value of 10
  };

  const wrapText = (text, width = 50) => {
    let words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if ((currentLine + " " + word).length > width) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += (currentLine ? " " : "") + word;
      }
    });

    lines.push(currentLine); // Push the remaining line
    return lines.map((line, index) => (
      <tspan key={index} x="0" dy={index === 0 ? 0 : 10}>
        {line}
      </tspan>
    ));
  };

  const CustomizedTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={10} textAnchor="middle" fontSize={12} fill="#666">
          {wrapText(payload.value, 20)}
        </text>
      </g>
    );
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      {/* Sales Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Sales - Environment Management
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={salesData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="category"
              tick={<CustomizedTick />}
              tickLine={true} // Ensures ticks are visible
              interval={0} // Forces all labels to be shown
            />

            <YAxis domain={getYAxisDomain()} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="progress"
              fill="#2C7C69"
              name="Average Score"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => (value ? value.toFixed(1) : ""),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="progress"
              fill="#7FC8A9"
              name="Max"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Service Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Service - Environment Management
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={serviceData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="category"
              tick={<CustomizedTick />}
              tickLine={true} // Ensures ticks are visible
              interval={0} // Forces all labels to be shown
            />

            <YAxis domain={getYAxisDomain()} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="progress"
              fill="#2C7C69"
              name="Average Score"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => (value !== 0 ? value.toFixed(1) : ""),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="progress"
              fill="#7FC8A9"
              name="Max"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EnvironmentChart;
