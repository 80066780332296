import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const GeneralChart = ({ data }) => {
  // Function to filter data based on category
  const filterDataByCategory = (category) =>
    data.filter((entry) => entry.category === category);

  // Function to compute average values and max values for comparison
  const aggregateData = (filteredData) => {
    let total_1s_and_2s = 0;
    let count = 0;
    const maxValues = { "1s_and_2s": 5 };

    filteredData.forEach((entry) => {
      const value = Number(entry["1s_and_2s"]);
      if (!isNaN(value)) {
        total_1s_and_2s += value;
        count += 1;
      }
    });

    const avgValue = count > 0 ? total_1s_and_2s / count : 0;

    // Helper function to clamp values between 0 and maxValue for graph display
    const clampForGraph = (value) => Math.max(0, value);
    const clampRemaining = (avgValue, maxValue) =>
      Math.max(0, Math.min(maxValue, maxValue - avgValue));

    return [
      {
        category: "1s and 2s",
        avgValue: avgValue,
        avgValueForGraph: clampForGraph(avgValue),
        maxValue: maxValues["1s_and_2s"],
        remainingToMax: clampRemaining(avgValue, maxValues["1s_and_2s"]),
      },
    ];
  };

  const salesData = aggregateData(filterDataByCategory("Sales"));
  const serviceData = aggregateData(filterDataByCategory("Service"));

  // Function to determine Y-Axis domain with fixed max value of 5
  const getYAxisDomain = () => [0, 5]; // Set max value to 5

  // Use the fixed Y-axis domain for both sales and service
  const salesYAxisDomain = getYAxisDomain();
  const serviceYAxisDomain = getYAxisDomain();

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,
              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      {/* Sales Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Sales - General
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={salesData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={salesYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#b0b0b0"
              name="Average Score"
              label={{
                position: "insideTop",
                fill: "#000",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#e3e3e3"
              name="Max"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Service Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Service - General
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={serviceData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={serviceYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#b0b0b0"
              name="Average Score"
              label={{
                position: "insideTop",
                fill: "#000",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#e3e3e3"
              name="Max"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GeneralChart;
