import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog"; // PrimeReact modal component
import HeadStepper from "./HeadStepper";
import { Tooltip } from "primereact/tooltip";
import Swal from "sweetalert2";

const LCA = ({ data, getData, edit,getStatus }) => {

  const defaultPartData = {
    partNo: "",
    partDescription: "",
    imdsId: "",
    data: [

      {
        "categoryType": "process",
        files: [],
        "categories": [
          {
            "name": "Manufacturing Process (Assembly / Forging / Casting / Stamping)",
            "details": [
              {
                "detail": "",
                "remarks": ""
              }
            ]
          },
          {
            "name": "Special Process (Heat Treatment / Plating / Coating)",
            "details": [
              {
                "detail": "",
                "remarks": ""
              }
            ]
          }
        ],

      },

      {
        "categoryType": "energy",
        "categories": [
          {
            "name": "Electricity",
            "details": [
              {
                "type": "",
                "units (in Kilowatt-hours)": null,

                "remarks": ""
              }

            ]
          },
          {
            "name": "Fuel",
            "details": [
              {
                "type": "",
                "units (in Liters)": null,
                "remarks": ""
              }
            ]
          },
          {
            "name": "Gas",
            "details": [
              {
                "type": "",
                "units (in Cubic meters)": null,
                "remarks": ""
              }
            ]
          }
        ]
      },
      {
        "categoryType": "specialProcessMaterial",
        "categories": [
          {
            "name": "Utilities & Auxiliaries (Water / Coolant / Lubricant / Paint / Plating)",
            "details": [
              {
                "nameOrGrade": "",
                "quantity": null,
                "unit of measure": "",
                "remarks": ""
              }
            ]
          }
        ]
      },

      {
        "categoryType": "waste",
        "categories": [
          {
            "name": "Air Emission",
            "details": [
              {
                "materialName": "",
                "quantity (in milligrams per cubic meter)": null,

                "remarks": ""
              }
            ]
          },
          {
            "name": "Water Emission",
            "details": [
              {
                "materialName": "",
                "quantity (in milligrams per liter)": null,
                "remarks": ""
              }
            ]
          },
          {
            "name": "Solid Waste",
            "details": [
              {
                "materialName": "",
                "quantity (in Kilogram)": null,
                "remarks": ""
              }
            ]
          }
        ]
      },

      {
        "categoryType": "transportation",
        "categories": [
          {
            "name": "Transportation",
            "details": [
              {
                "mode": "",
                "from (Manufacturing Location)": "",
                "to (TVS Motor Plant)": "",
                "distance": null,
                "unit of Measure": "",
                "remarks": ""
              }
            ]
          }
        ]
      }

    ],
    process: ['Process', 'Energy Consumption', 'Special Process Material', 'Waste', 'Transportation'],
    completed_stage: [],


  };

  const [newPart, setNewPart] = useState({ partNo: "", partDescription: "", imdsId: "" });
  const [partList, setPartList] = useState(data);
  const [selectedPart, setSelectedPart] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [partModal, setPartModal] = useState(false)
  const [selectedStage, setSelectedStage] = useState(""); // NEW STATE: Stores the selected step
  useEffect(() => {
    getData(partList);
    getStatus(  partList?.every(x=> x?.completed_stage?.length === 5 && x?.completed_stage?.every(y => y?.status === 'finalized')) )
  }, [partList])


  const handleNewPartChange = (field, value) => {
    setNewPart((prev) => ({ ...prev, [field]: value }));
  };

  const processOptions = [
    "Assembly",
    "Casting",
    "Forging",
    "Machining",
    "Welding",
    "Stamping",
    "Injection Molding",
    "Extrusion",
    "3D Printing",
    "Brazing and Soldering",
    "Heat Treatment",
    "Other (Provide details in remarks)"
  ];

  const finishingOptions = [
    "Electroplating",
    "Painting",
    "Powder Coating",
    "Passivation",
    "Galvanizing",
    "Electrolytic polishing",
    "Anodizing",
    "Other (Provide details in remarks)"
  ];

  const handleAddPart = () => {
    if (!newPart.partNo.trim()) {
      alert("Please enter a valid Part No.");
      return;
    }
    setPartList([...partList, { ...newPart, data: JSON.parse(JSON.stringify(defaultPartData.data)), process: defaultPartData.process, completed_stage: [] }]);
    setNewPart({ partNo: "", partDescription: "", imdsId: "" });
    setPartModal(false);
  };

  // Open Modal & Set Selected Part
  const openModal = (part) => {
    setSelectedPart(JSON.parse(JSON.stringify(part)));
    setSelectedStage(""); // Reset selected stage when opening the modal
    setIsModalVisible(true);
  };

  const handleStepClick = (stage) => {
    console.log(stage)
    setSelectedStage(stage);
  };

  // Filter Data Based on Selected Step
  const getFilteredCategoryData = () => {
    if (!selectedPart || !selectedStage) return null;

    let categoryType = "";
    switch (selectedStage) {

      case "Process":
        categoryType = "process";
        break;
      case "Energy Consumption":
        categoryType = "energy";
        break;
      case "Special Process Material":
        categoryType = "specialProcessMaterial";
        break;
      case "Waste":
        categoryType = "waste";
        break;
      case "Transportation":
        categoryType = "transportation";
        break;
      default:
        return null;
    }

    return selectedPart.data.find((item) => item.categoryType === categoryType);
  };

  const saveProgress = () => {
    if (selectedPart && selectedStage) {
      // setPartList((prevPartList) =>
      //   prevPartList.map((part) => {
      //     if (part.partNo === selectedPart.partNo) {
      //       // Ensure `completed_stage` is initialized as an array
      //       const updatedStages = [...(part.completed_stage || [])];

      //       // Check if the stage already exists
      //       const existingIndex = updatedStages.findIndex((s) => s.stage === selectedStage);

      //       if (existingIndex === -1) {
      //         updatedStages.push({ stage: selectedStage, status: "drafted" });
      //       } else {
      //         updatedStages[existingIndex].status = "drafted";
      //       }

      //       const updatedPart = { ...part, completed_stage: updatedStages };

      //       // ✅ Also update selectedPart to reflect changes in the modal
      //       setSelectedPart(updatedPart);

      //       return updatedPart;
      //     }
      //     return part;
      //   })
      // );

      setPartList((prevPartList) =>
        prevPartList.map((part) => {
          if (part.partNo === selectedPart.partNo) {
            // Initialize as an empty array if undefined
            const updatedStages = part.completed_stage ? [...part.completed_stage] : [];
            console.log(selectedStage)
            // Check if the stage already exists
            const existingIndex = updatedStages.findIndex((s) => s.stage === selectedStage);

            if (existingIndex === -1) {
              // Push new stage
              updatedStages.push({ stage: selectedStage, status: "drafted" });
            } else {
              // Update existing stage
              updatedStages[existingIndex] = {
                ...updatedStages[existingIndex],
                status: "drafted"
              };
            }

            // Create a new object reference for React to detect change
            const updatedPart = { ...selectedPart, completed_stage: [...updatedStages] };

            // ✅ Update selectedPart to reflect changes in the modal
            setSelectedPart(updatedPart);

            return updatedPart;
          }
          return part;
        })
      )
      Swal.fire("Success!", "Progress draft successfully.", "success")

    }
  };
  const handleTVSSubmit = async () => {
    const { value: accept } = await Swal.fire({

      html: `<div style="overflow:auto;max-height:200px" >Are you sure you wish to submit details of ${selectedPart.partNo} ? You cannot make further edits once you do so</div>`,

      confirmButtonColor: '#315975',
      showCancelButton: true,

      confirmButtonText:
        'Submit',

    })
    if (accept) {
      console.log(partList)
      setIsModalVisible(false)
    }
  }
  const finalizeStage = () => {
    if (selectedPart && selectedStage) {
      const { valid, data, message, location } = validateData(JSON.parse(JSON.stringify([selectedPart])))
      if (valid) {
        setPartList((prevPartList) =>
          prevPartList.map((part) => {
            if (part.partNo === selectedPart.partNo) {
              // Initialize as an empty array if undefined
              const updatedStages = part.completed_stage ? [...part.completed_stage] : [];
              console.log(selectedStage)
              // Check if the stage already exists
              const existingIndex = updatedStages.findIndex((s) => s.stage === selectedStage);

              if (existingIndex === -1) {
                // Push new stage
                updatedStages.push({ stage: selectedStage, status: "finalized" });
              } else {
                // Update existing stage
                updatedStages[existingIndex] = {
                  ...updatedStages[existingIndex],
                  status: "finalized"
                };
              }

              // Create a new object reference for React to detect change
              const updatedPart = { ...selectedPart, completed_stage: [...updatedStages] };

              // ✅ Update selectedPart to reflect changes in the modal
              setSelectedPart(updatedPart);

              return updatedPart;
            }
            return part;
          })
        )
        Swal.fire("Success!", "Progress saved successfully.", "success")

      } else if (!valid && message) {

        setSelectedStage(stageMapping[location.categoryType])
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: message,
        });
      }

    }
  };



  const categoryDescriptions = {

    Process: "Describe the key manufacturing and finishing processes involved in producing the part (e.g., machining, forging, casting, coating). You are also required to upload the process flow diagram associated with this specific part. ",
    "Energy Consumption": "Enter the energy required for the manufacturing process, specifying the type of energy used (e.g., electricity, gas) and the consumption per unit of production. ",
    "Special Process Material": "List any specialized materials or chemicals used in processes such as heat treatment, plating, or surface finishing.",
    Waste: "Provide details of waste generated during production, including type (solid, liquid, gaseous), estimated quantity, and any treatment or disposal methods used. ",
    Transportation: "Indicate the mode(s) of transportation used to deliver the part to TVS Motor, along with relevant details such as distance traveled and frequency of shipments."
  };
  function validateData(data) {
    for (let parentIndex = 0; parentIndex < data.length; parentIndex++) {
      const item = data[parentIndex];
      for (let dataIndex = 0; dataIndex < item.data.length; dataIndex++) {
        const category = item.data[dataIndex];
        for (let categoryIndex = 0; categoryIndex < category.categories.length; categoryIndex++) {
          const cat = category.categories[categoryIndex];
          const details = cat.details;

          // Check if categoryType is 'process'
          if (category.categoryType === "process") {
            for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
              const detail = details[detailIndex];
              // If 'Other' is present, 'remarks' must not be empty
              if (
                detail.detail.toLowerCase().includes("other") &&
                detail.remarks.trim() === ""
              ) {
                return {
                  valid: false,
                  message: `Remarks cannot be empty when detail is 'Other' for category "${cat.name}".`,
                  location: {
                    partNo: item.partNo,
                    categoryType: category.categoryType,
                    categoryName: cat.name,
                    parentIndex,
                    dataIndex,
                    categoryIndex,
                    detailIndex
                  }
                };
              }
            }
          } else {
            // For other category types
            for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
              const detail = details[detailIndex];

              // Check if all keys are empty
              const allKeysEmpty = Object.values(detail).every(value => !value);

              // Check if only 'remarks' is empty
              const onlyRemarksEmpty = Object.keys(detail).every(key => {
                if (key === "remarks") return detail[key].trim() === "";
                if (key.includes('quantity') || key.includes('distance') || key.includes('(in')) return detail[key] != null
                return detail[key].trim() !== "";
              });

              // If not all keys are empty and remarks is not the only empty key
              if (!allKeysEmpty && !onlyRemarksEmpty) {
                return {
                  valid: false,
                  message: `Details are invalid for category "${cat.name}". Except remarks other are mandatory or all fields can be empty`,
                  location: {
                    partNo: item.partNo,
                    categoryType: category.categoryType,
                    categoryName: cat.name,
                    parentIndex,
                    dataIndex,
                    categoryIndex,
                    detailIndex
                  }
                };
              }
            }
          }

          // Filter the details array to remove trailing empty objects
          cat.details = cat.details.filter((detail, index) => {
            // Check if all keys are empty
            const allKeysEmpty = Object.values(detail).every(value => !value);
            // Keep non-empty objects or if it's not a trailing empty object
            return !allKeysEmpty || index < cat.details.length - 1;
          });
        }
      }
    }

    // If all checks pass, return the filtered data
    return { valid: true, data };
  }

  const dialogFooter = (
    <div className="d-flex justify-content-end align-items-center">
      {selectedStage && (
        <>
          <Tooltip target=".disabled-button" />
          {/* Save Progress Button - Orange */}
          <Button
            className="ml-2"

            outlined
            label="Save Progress"
            onClick={saveProgress}
            style={{ backgroundColor: "#ffa500", color: "#fff", border: "none", width: 200 }}
          />

          {/* Save & Finalize Button - Green */}
          <Button
            label={`Save & Finalize ${selectedStage}`}
            onClick={finalizeStage}
            style={{ backgroundColor: "#28a745", color: "#fff", border: "none", width: 200 }}
          />

        </>
      )}
    </div>
  );
  const [newDetails, setNewDetails] = useState({});


  const handleDetailChange = (catIndex, detailIndex, key, value) => {
    setSelectedPart((prev) => {
      const updatedPart = { ...prev };
      updatedPart.data
        .find((item) => item.categoryType === getCategoryTypeFromStage())
        .categories[catIndex]
        .details[detailIndex][key] = value;
      return updatedPart;
    });
  };
  const stageMapping = {
    "process": "Process",
    "energy": "Energy Consumption",
    "specialProcessMaterial": "Special Process Material",
    "waste": "Waste",
    "transportation": "Transportation"
  }
  const getCategoryTypeFromStage = () => {
    switch (selectedStage) {

      case "Process":
        return "process";
      case "Energy Consumption":
        return "energy";
      case "Special Process Material":
        return "specialProcessMaterial";
      case "Waste":
        return "waste";
      case "Transportation":
        return "transportation";
      default:
        return "";
    }
  };
  // ✅ Initialize Empty New Detail Input Fields
  const initializeNewDetail = (catIndex, template) => {
    setNewDetails((prev) => ({
      ...prev,
      [catIndex]: Object.keys(template).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {}),
    }));
  };

  // ✅ Handle Input Change for New Detail
  const handleNewDetailChange = (catIndex, key, value) => {
    setNewDetails((prev) => ({
      ...prev,
      [catIndex]: { ...prev[catIndex], [key]: value },
    }));
  };

  // ✅ Add New Detail to the Category
  const handleAddDetail = (catIndex) => {
    if (!newDetails[catIndex]) return;
    setSelectedPart((prev) => {
      const updatedPart = { ...prev };
      updatedPart.data
        .find((item) => item.categoryType === getCategoryTypeFromStage())
        .categories[catIndex]
        .details.push(newDetails[catIndex]);
      return updatedPart;
    });

    setNewDetails((prev) => ({
      ...prev,
      [catIndex]: null,
    }));
  };

  // ✅ Remove Detail from Category
  const handleRemoveDetail = (catIndex, detailIndex) => {
    setSelectedPart((prev) => {
      const updatedPart = { ...prev };
      updatedPart.data
        .find((item) => item.categoryType === getCategoryTypeFromStage())
        .categories[catIndex]
        .details.splice(detailIndex, 1);
      return updatedPart;
    });
  }

  const handleFileUpload = (files) => {
    const updatedData = { ...selectedPart };
    const processCategory = updatedData.data.find((item) => item.categoryType === "process");

    // Append new files to the existing ones
    processCategory.files = [...(processCategory.files || []), ...Array.from(files)];
    setSelectedPart(updatedData);
  };

  const handleFileRemove = (fileIndex) => {
    const updatedData = { ...selectedPart };
    const processCategory = updatedData.data.find((item) => item.categoryType === "process");

    // Remove file at the specified index
    processCategory.files.splice(fileIndex, 1);
    setSelectedPart(updatedData);
  };


  return (
    <div style={{ margin: "0 auto", padding: 20 }}>
      <h1>LCA Data Collection Form</h1>
      <p>As part of TVS Motor’s commitment to Net Zero and sustainability, we are enhancing transparency regarding the environmental impact of our supply chain. To support this effort, we require our valued suppliers to provide key information on the materials and manufacturing processes associated with the parts supplied to TVS. The data collected will aid in emissions studies, helping us assess and reduce the carbon footprint of our products. Your input is essential in driving sustainable manufacturing and achieving our shared sustainability goals. Please ensure accurate and complete data submission to enable meaningful analysis.</p>

      <h1>Parts supplied to TVS Motors</h1>
      <p>Identify and list all part numbers supplied to TVS Motor. For each part, provide a clear description and the approved IMDS ID. Click on each listed part and follow the provided guidance to enter details specific to that part, including manufacturing and finishing processes, special processes, energy and water usage, waste generation, and transportation.  You are also required to attach the process flow document for each part to ensure a complete understanding of its production lifecycle</p>

      {partList.length > 0 && (
        <div className="" style={{ marginBottom: "10px" }}>
          {partList.map((part, index) => {
            return (
              <div
                key={index}
                className="p-card p-shadow-3 p-mb-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1rem",
                  marginBottom: 10,
                }}
              >
                <h3
                  onClick={() => openModal(part)}
                  style={{ cursor: "pointer", color: "#007ad9" }}
                >
                  {part.partNo}
                </h3>

                {/* ✅ Display Process Data with Custom Radio Buttons */}
                {part.process?.length > 0 ? (
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {part.process.map((item, i) => {
                      // ✅ Check if this stage is "finalized" or "drafted"
                      const stageStatus = part.completed_stage?.find((s) => s.stage === item);
                      const isFinalized = stageStatus?.status === "finalized";
                      const isDrafted = stageStatus?.status === "drafted";

                      return (
                        <label
                          key={i}
                          style={{
                            marginRight: "15px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          {/* ✅ Custom Radio Button with Different Styles */}
                          <div
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: `2px solid ${isFinalized ? "#28a745" : isDrafted ? "#ffa500" : "#ccc"}`,
                              backgroundColor: isFinalized ? "#28a745" : isDrafted ? "#ffa500" : "transparent",
                              color: isFinalized ? "#fff" : "transparent",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {isFinalized ? "✔" : ""}
                          </div>

                          {/* Label Text */}
                          <span style={{ marginLeft: "5px" }}>{item}</span>
                        </label>
                      );
                    })}
                  </div>
                ) : (
                  <p style={{ color: "red" }}>No process data available</p>
                )}
              </div>
            );
          })}
        </div>
      )}
      {edit && <>

        <Button
          label="Add Part"
          icon="pi pi-plus"
          className="p-button-text mb-3"
          style={{
            width: "200px",
            backgroundColor: "#003366", // Dark blue background
            color: "#fff", // White text
            border: "none", // Remove border
          }}
          onClick={() => setPartModal(!partModal)}
        />


        {partModal && (
          <>
            <div className="p-card p-component p-shadow-3 p-mb-3" style={{ padding: "1rem" }}>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ display: "block", fontWeight: "bold" }}>Part No: <span className="mandatory ml-1">*</span> </label>
                <InputText
                  value={newPart.partNo}
                  disabled={!edit}
                  onChange={(e) => handleNewPartChange("partNo", e.target.value)}
                  className="p-inputtext-sm"
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ display: "block", fontWeight: "bold" }}>Part Description: </label>
                <InputTextarea
                  disabled={!edit}
                  value={newPart.partDescription}
                  onChange={(e) => handleNewPartChange("partDescription", e.target.value)}
                  className="p-inputtext-sm"
                  rows={4}
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ display: "block", fontWeight: "bold" }}>Approved IMDS ID:</label>
                <InputText
                  disabled={!edit}
                  value={newPart.imdsId}
                  onChange={(e) => handleNewPartChange("imdsId", e.target.value)}
                  className="p-inputtext-sm"
                  style={{ width: "100%" }}
                />
              </div>
              {edit && <Button
                label=""
                icon="pi pi-plus"
                className="p-button-lg"
                style={{
                  backgroundColor: "#003366", // Dark Blue Color
                  color: "#fff", // White text
                  border: "none", // Remove border
                  padding: "10px 20px", // Adjust padding for better appearance
                  fontSize: "16px", // Slightly larger font size
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}

                onClick={handleAddPart}
              />}

            </div>
          </>
        )}
      </>}

      {/* MODAL / DIALOG */}
      <Dialog header="Part Details" visible={isModalVisible} style={{ width: "60vw" }} onHide={() => setIsModalVisible(false)} footer={dialogFooter}>
        {selectedPart && (
          <>
            <div style={{
              marginBottom: "2rem",
              paddingBottom: "20px",
              borderBottom: "2px solid #ccc"
            }}>
              {/* Part No */}
              <div style={{ marginBottom: "5px" }}>
                {/* ✅ Part No */}
                <strong style={{ color: "#333", marginBottom: "3px", display: "block", fontSize: "16px" }}>Part No:</strong>
                <p style={{ fontSize: "18px" }}>{selectedPart.partNo}</p>
              </div>

              {/* ✅ Part Description */}
              <div style={{ marginBottom: "5px" }}>
                <strong style={{ color: "#333", marginBottom: "3px", display: "block", fontSize: "16px" }}>Part Description:</strong>
                <p style={{ fontSize: "16px", color: "#555" }}>{selectedPart.partDescription}</p>
              </div>

              {/* ✅ Approved IMDS ID */}
              <div>
                <strong style={{ color: "#333", marginBottom: "3px", display: "block", fontSize: "16px" }}>Approved IMDS ID:</strong>
                <p style={{ fontWeight: "bold", fontSize: "16px", color: "#555" }}>
                  {selectedPart.imdsId}
                </p>
              </div>

            </div>

            {/* Stepper with Click Handling */}
            <HeadStepper stages={selectedPart.process} onStepClick={handleStepClick} selectedStage={selectedStage} completedStages={selectedPart.completed_stage} />

            {/* Render Accordion Based on Step Selection */}
            {selectedStage && (
              <div>
                <h1>{selectedStage}</h1>
                <p style={{ fontStyle: "italic", marginBottom: "1rem", color: "#555" }}>
                  {categoryDescriptions[selectedStage]}
                </p>
                {selectedStage === "Process" && (
                  <div
                    style={{
                      marginBottom: "15px",
                      padding: "15px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <h1 style={{ marginBottom: "15px", fontSize: 15, color: "#333" }}>Upload Process Flow diagram</h1>

                    {/* File Upload Input */}
                    <input
                      type="file"

                      multiple
                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                      onChange={(e) => handleFileUpload(e.target.files)}
                      style={{
                        display: "block",
                        marginBottom: "15px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#333",
                      }}
                    />

                    {/* Display Uploaded Files */}
                    {getFilteredCategoryData()?.files?.length > 0 && (
                      <div style={{ marginTop: "10px" }}>
                        <h1 style={{ color: "#555", fontSize: 15, marginBottom: "10px" }}>Uploaded Files:</h1>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          {getFilteredCategoryData()?.files.map((file, fileIndex) => (
                            <li
                              key={fileIndex}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "8px",
                                padding: "10px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                {file.name}
                              </span>
                              {edit && <Button
                                icon="pi pi-trash"
                                className="p-button-danger p-button-sm"
                                onClick={() => handleFileRemove(fileIndex)}
                              />}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}


                <Accordion >
                  {getFilteredCategoryData()?.categories.map((category, catIndex) => (
                    <AccordionTab key={catIndex} header={category.name}>



                      {/* ✅ Display Details in Table Format */}
                      <table className="p-datatable p-component" style={{ width: "100%", borderCollapse: "collapse", marginTop: "1rem" }}>
                        <thead>
                          <tr>
                            {category.details.length > 0 &&
                              Object.keys(category.details[0]).map((key) => (
                                <th key={key} style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                                  {key.replace(/([A-Z])/g, " $1") // Insert space before capital letters
                                    .replace(/^./, (str) => str.toUpperCase()) // Capitalize first letter
                                  }
                                </th>
                              ))}

                            <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {category.details.map((detail, detailIndex) => (
                            <tr key={detailIndex}>
                              {Object.entries(detail).map(([key, value]) => (
                                <td key={key} style={{ border: "1px solid #ddd", padding: "8px" }}>
                                  {/* Render dropdowns for 'detail' or 'unit' */}
                                  {key === "detail" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      disabled={!edit}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select an option --</option>
                                      {(category.name === "Manufacturing Process (Assembly / Forging / Casting / Stamping)"
                                        ? processOptions
                                        : finishingOptions
                                      ).map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  ) : key === "to (TVS Motor Plant)" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Location --</option>
                                      <option value="hsr">TVSM-Hosur</option>
                                      <option value="mysr">TVSM-Mysore</option>
                                      <option value="nlg">TVSM-Nalagarh</option>

                                    </select>
                                  ) : key === "unit" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : key === "unit of" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : (key.includes('quantity') || key.includes('distance') || key.includes('(in'))
                                    ?
                                    (
                                      <input
                                        type='number'
                                        value={value}
                                        onChange={(e) =>
                                          handleDetailChange(catIndex, detailIndex, key, e.target.value ? parseFloat(e.target.value) : null)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )
                                    : (
                                      <input
                                        type="text"
                                        value={value}
                                        onChange={(e) =>
                                          handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )}
                                </td>
                              ))}
                              {/* Delete Button */}
                              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                {category.details.length > 1 && (
                                  <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-sm"
                                    onClick={() => handleRemoveDetail(catIndex, detailIndex)}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}

                          {/* Add New Detail Row */}
                          {newDetails[catIndex] && (
                            <tr>
                              {Object.keys(category.details[0] || {}).map((key) => (
                                <td key={key} style={{ border: "1px solid #ddd", padding: "8px" }}>
                                  {key === "detail" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      disabled={!edit}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select an option --</option>
                                      {(category.name === "Manufacturing Process (Assembly / Forging / Casting / Stamping)"
                                        ? processOptions
                                        : finishingOptions
                                      ).map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  ) : key === "to (TVS Motor Plant)" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Location --</option>
                                      <option value="hsr">TVSM-Hosur</option>
                                      <option value="mysr">TVSM-Mysore</option>
                                      <option value="nlg">TVSM-Nalagarh</option>

                                    </select>
                                  ) : key === "unit" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : key === "unit of" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : (key.includes('quantity') || key.includes('distance') || key.includes('(in'))
                                    ?
                                    (
                                      <input
                                        type='number'
                                        value={newDetails[catIndex].value}
                                        onChange={(e) =>
                                          handleNewDetailChange(catIndex, key, e.target.value ? parseFloat(e.target.value) : null)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )
                                    : (
                                      <input
                                        type="text"
                                        value={newDetails[catIndex].value}
                                        onChange={(e) =>
                                          handleNewDetailChange(catIndex, key, e.target.value)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )}
                                </td>
                              ))}
                              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                <Button
                                  icon="pi pi-check"
                                  className="p-button-success p-button-sm"
                                  onClick={() => handleAddDetail(catIndex)}
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>



                      </table>

                      {/* ✅ Add More Button */}
                      <Button
                        label="Add More"
                        icon="pi pi-plus"
                        className="p-button-text p-button-sm"
                        onClick={() => initializeNewDetail(catIndex, category.details[0])}
                        style={{ marginTop: "10px" }}
                      />
                    </AccordionTab>
                  ))}
                </Accordion>

              </div>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
}

export default LCA;
