import React, { useState, useEffect } from "react";
import dealerData from "./dealer.json";
import SideBarGraph from "./SidebarGraph";

import { Card } from "primereact/card";
import EnvironmentChart from "./EnvironmentChart";
import SocialChart from "./SocialChart";
import GovernanceChart from "./GovernanceChart";
import GeneralChart from "./GeneralChart";
import MSIGradeChart from "./MSIGradeChart";
import FilterComponent from "./FilterComponent";
import ESGGChart from "./ESGGChart";
import ESGGOverviewChart from "./ESGGOverviewChart";
import ESGGScores from "./ESGGScores";
import MSIScoresOverTime from "./MSIScoresOverTime";
import { Tabs, Tab } from "@mui/material";
import SideBarTab from "./TabGraphs/SideBarTab";
import MSIScoresOverTimeTab from "./TabGraphs/MSIScoresOverTimeTab";
import ESGOverviewTab from "./TabGraphs/ESGOverviewTab";
import ESGScoresTab from "./TabGraphs/ESGScoresTab";
import EnvironmentTab from "./TabGraphs/EnvironmentTab";
import SocialTab from "./TabGraphs/SocialTab";
import GovernanceTab from "./TabGraphs/GovernanceTab";

const DealerDashboard = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0); // Track the active tab

  useEffect(() => {
    setData(dealerData);
    setFilteredData(dealerData);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderTab2Content = () => (
    <div>
      <div className="row">
        <SideBarTab tabIndex={tabIndex} />
      </div>
      <div className="row">
        <MSIScoresOverTimeTab tabIndex={tabIndex} />
      </div>
      <br />
      <div className="card">
        <ESGScoresTab tabIndex={tabIndex} />
      </div>
      <div className="row">
        <div className="card p-2 col-md-4 border">
          <EnvironmentTab tabIndex={tabIndex} />
        </div>
        <div className="card p-2 col-md-4 border">
          <SocialTab tabIndex={tabIndex} />
        </div>
        <div className="card p-2 col-md-4 border">
          <GovernanceTab tabIndex={tabIndex} />
        </div>
      </div>
    </div>
  );

  const renderTab1Content = () => (
    <div>
      <div className="row">
        <SideBarGraph />
      </div>
      <div className="row">
        <MSIScoresOverTime />
      </div>
      <br />
      <div className="card p-2 col-md-12 border">
        <FilterComponent data={data} setFilteredData={setFilteredData} />
      </div>
      <div className="row">
        <div className="card p-2 col-md-6 border">
          <ESGGOverviewChart data={filteredData} />
        </div>

        <div className="card p-2 col-md-6 border">
          <MSIGradeChart data={filteredData} />
        </div>

        <div className="card p-2 col-md-4 border">
          <ESGGScores data={filteredData} />
        </div>

        <div className="card p-2 col-md-8 border">
          <ESGGChart data={filteredData} />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="card p-2 col-md-12 border">
          <EnvironmentChart data={filteredData} />
        </div>

        <div className="card p-2 col-md-12 border">
          <SocialChart data={filteredData} />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="card p-2 col-md-12 border">
          <GovernanceChart data={filteredData} />
        </div>

        <div className="card p-2 col-md-12 border">
          <GeneralChart data={filteredData} />
        </div>
      </div>
    </div>
  );

  const renderTab3Content = () => (
    <div>
      <div className="row">
        <SideBarTab tabIndex={tabIndex} />
      </div>
      <div className="row">
        <MSIScoresOverTimeTab tabIndex={tabIndex} />
      </div>
      <br />
      <div className="card">
        <ESGScoresTab tabIndex={tabIndex} />
      </div>
      <div className="row">
        <div className="card p-2 col-md-4 border">
          <EnvironmentTab tabIndex={tabIndex} />
        </div>
        <div className="card p-2 col-md-4 border">
          <SocialTab tabIndex={tabIndex} />
        </div>
        <div className="card p-2 col-md-4 border">
          <GovernanceTab tabIndex={tabIndex} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="AMD, ADO, AO tabs"
      >
        <Tab label="Dealers" />
        <Tab label="APS" />
        <Tab label="Area Office" />
      </Tabs>

      {/* Render content based on selected tab */}
      {tabIndex === 0 && <div>{renderTab1Content()}</div>}
      {tabIndex === 1 && <div>{renderTab2Content()}</div>}
      {tabIndex === 2 && <div>{renderTab3Content()}</div>}
    </div>
  );
};

export default DealerDashboard;
