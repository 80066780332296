import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const GovernanceTab = ({ tabIndex }) => {
  const tab2Data = [
    {
      category: "Legal Requirement",
      avgValueForGraph: 4,
      maxValue: 5,
      remainingToMax: 1,
    },
  ];

  const tab3Data = [
    {
      category: "Legal Requirement",
      avgValueForGraph: 5,
      maxValue: 5,
      remainingToMax: 0,
    },
  ];

  const selectedData =
    tabIndex === 1 ? tab2Data : tabIndex === 2 ? tab3Data : [];

  const fixedYAxisDomain = [0, 5];

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,
              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      <div style={{ width: "100%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Governance Management
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={selectedData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 7 }} />
            <YAxis domain={fixedYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValueForGraph.toFixed(2)} (Max: ${
                      payload.maxValue
                    })`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#4A90E2"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#AFCBFF"
              name="Maximum"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GovernanceTab;
