import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";



const FiltersSectionDemo = ({ supplyData, setFilteredSupplyData,setSelectedBu }) => {
  const [category, setCategory] = useState("All");
  const [businessUnit, setBusinessUnit] = useState("All");
  const [location, setLocation] = useState("All Sites");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [categories, setCategories] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [rankedData, setRankedData] = useState([]);

  useEffect(() => {
    if (supplyData.length > 0) {
      // Rank the data initially (without filtering)
      let rankedData = [...supplyData].sort(
        (a, b) => b.msi_score - a.msi_score
      );

      rankedData = rankedData.map((item, index) => ({
        ...item,
        rank: index + 1, // Rank starts from 1
      }));

      setRankedData(rankedData); // Store the ranked data in the state
      setFilteredSupplyData(rankedData); // Set the initial filtered data to the ranked data

      // Set categories, business units, and locations for dropdowns
      setCategories([
        "All",
        ...new Set(supplyData.map((item) => item.category)),
      ]);
      setBusinessUnits([
        "All",
        ...new Set(
          supplyData.map(
            (item) =>
              `${item.company_short_name} - ${item.vendor_code}` || "N/A"
          )
        ),
      ]);
      setLocations([
        "All Sites",
        ...new Set(supplyData.map((item) => item.location)),
      ]);
    }
  }, [supplyData, setFilteredSupplyData]);

  useEffect(() => {
    setSelectedBu(businessUnit)
    applyFilters();
  }, [category, businessUnit, location, fromDate, toDate]);

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
    setBusinessUnit("All");
    setLocation("All Sites");

    if (newCategory === "All") {
      setBusinessUnits([
        "All",
        ...new Set(
          supplyData.map(
            (item) =>
              `${item.company_short_name} - ${item.vendor_code}` || "N/A"
          )
        ),
      ]);
      setLocations([
        "All Sites",
        ...new Set(supplyData.map((item) => item.location)),
      ]);
    } else {
      setBusinessUnits([
        "All",
        ...new Set(
          supplyData
            .filter((item) => item.category === newCategory)
            .map((item) => `${item.company_short_name} - ${item.vendor_code}`)
        ),
      ]);
      setLocations(["All Sites"]);
    }
  };

  const handleBusinessUnitChange = (newBusinessUnit) => {
    setBusinessUnit(newBusinessUnit);
    setLocation("All Sites");

    if (newBusinessUnit === "All") {
      setLocations([
        "All Sites",
        ...new Set(supplyData.map((item) => item.location)),
      ]);
    } else {
      const selectedVendorCode = newBusinessUnit.split(" - ").pop();
      setLocations([
        "All Sites",
        ...new Set(
          supplyData
            .filter(
              (item) => item.vendor_code.toString() === selectedVendorCode
            )
            .map((item) => item.location)
        ),
      ]);
    }
  };

  const applyFilters = () => {
    let filteredData = [...rankedData]; // Use ranked data, not raw supplyData

    if (category !== "All") {
      filteredData = filteredData.filter((item) => item.category === category);
    }

    if (businessUnit !== "All") {
      const selectedVendorCode = businessUnit.split(" - ").pop();
      filteredData = filteredData.filter(
        (item) => item.vendor_code.toString() === selectedVendorCode
      );
    }

    if (location !== "All Sites") {
      filteredData = filteredData.filter((item) => item.location === location);
    }

    // Parse date function with fallback to null
    const parseDate = (dateString) => {
      if (!dateString) return null;
      const [day, month, year] = dateString.split(/[./-]/).map(Number);
      return new Date(year, month - 1, day);
    };

    if (fromDate) {
      filteredData = filteredData.filter((item) => {
        const startDate = parseDate(item.audit_start_date);
        return startDate && startDate >= fromDate;
      });
    }

    if (toDate) {
      filteredData = filteredData.filter((item) => {
        const endDate = parseDate(item.audit_end_date);
        return endDate && endDate <= toDate;
      });
    }

    setFilteredSupplyData(filteredData);
  };

  return (
    <div className="container gap-3 mt-4 d-flex">
      {/* Supplier Category */}
      <div>
        <label>Supplier Category</label>
        <Dropdown
          value={category}
          options={categories}
          onChange={(e) => handleCategoryChange(e.value)}
          placeholder="Select Category"
          className="w-100"
        />
      </div>

      {/* Business Unit */}
      <div>
        <label>Name & Vendor Code</label>
        <Dropdown
          value={businessUnit}
          options={businessUnits}
          onChange={(e) => handleBusinessUnitChange(e.value)}
          placeholder="Select Vendor"
          className="w-100"
        />
      </div>

      {/* Location */}
      <div>
        <label>Location</label>
        <Dropdown
          value={location}
          options={locations}
          onChange={(e) => setLocation(e.value)}
          placeholder="Select Location"
          className="w-100"
        />
      </div>

      {/* Date Filters */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>From</label>
        <Calendar
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.value);
            setToDate(null);
          }}
          placeholder="Select Date"
          showIcon
          showButtonBar
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>To</label>
        <Calendar
          value={toDate}
          onChange={(e) => setToDate(e.value)}
          placeholder="Select Date"
          showIcon
          minDate={fromDate}
          showButtonBar
          disabled={!fromDate}
        />
      </div>
    </div>
  );
};

export default FiltersSectionDemo;
