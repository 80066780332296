import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const EnvironmentTab = ({ tabIndex }) => {
  // Hardcoded data for Tab 2
  const tab3Data = [
    {
      category: "Waste Management",
      avgValue: 5.83,
      avgValueForGraph: 5.83,
      maxValue: 10,
      remainingToMax: 10 - 5.83,
    },
    {
      category: "Water Management",
      avgValue: 1.33,
      avgValueForGraph: 1.33,
      maxValue: 10,
      remainingToMax: 10 - 1.33,
    },
    {
      category: "Energy Management",
      avgValue: 1.87,
      avgValueForGraph: 1.87,
      maxValue: 10,
      remainingToMax: 10 - 1.87,
    },
    {
      category: "Legal Compliance",
      avgValue: 14.67,
      avgValueForGraph: 7.67,
      maxValue: 20,
      remainingToMax: 20 - 7.67,
    },
  ];

  // Hardcoded data for Tab 3
  const tab2Data = [
    {
      category: "Waste Management",
      avgValue: 5.5,
      avgValueForGraph: 5.5,
      maxValue: 10,
      remainingToMax: 4.5,
    },
    {
      category: "Water Management",
      avgValue: 1,
      avgValueForGraph: 1,
      maxValue: 10,
      remainingToMax: 9,
    },
    {
      category: "Energy Management",
      avgValue: 3.1,
      avgValueForGraph: 3.1,
      maxValue: 10,
      remainingToMax: 10 - 3.1,
    },
    {
      category: "Legal Compliance",
      avgValue: 7,
      avgValueForGraph: 7,
      maxValue: 10,
      remainingToMax: 3,
    },
  ];

  // Select the data based on tabIndex
  const selectedData =
    tabIndex === 1 ? tab2Data : tabIndex === 2 ? tab3Data : [];

  // Function to determine Y-Axis domain with a fixed max value (10)
  const getYAxisDomain = () => {
    return [0, 10]; // Adjusted to fit data with higher max value (Legal Compliance)
  };

  const wrapText = (text, width = 40) => {
    let words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if ((currentLine + " " + word).length > width) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += (currentLine ? " " : "") + word;
      }
    });

    lines.push(currentLine); // Push the remaining line
    return lines.map((line, index) => (
      <tspan key={index} x="0" dy={index === 0 ? 0 : 10}>
        {line}
      </tspan>
    ));
  };

  const CustomizedTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={10} textAnchor="middle" fontSize={7} fill="#666">
          {wrapText(payload.value, 20)}
        </text>
      </g>
    );
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,
              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      {/* Environment Chart */}
      <div style={{ width: "100%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Environment Management
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={selectedData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="category"
              tick={<CustomizedTick />}
              tickLine={true} // Ensures ticks are visible
              interval={0} // Forces all labels to be shown
            />
            <YAxis domain={getYAxisDomain()} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="progress"
              fill="#2C7C69"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => (value ? value.toFixed(1) : ""),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="progress"
              fill="#7FC8A9"
              name="Maximum"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EnvironmentTab;
