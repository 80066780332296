import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

const SuppliersTable = ({ data, assessorList, globalFilter, editSupplier }) => {
    const [databk, setDatabk] = useState(JSON.parse(JSON.stringify(data)))
    const [datas, setDatas] = useState(JSON.parse(JSON.stringify(data)))
    const [search, setSearch] = useState('')

    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }]

    const calibrationIdBodyTemplate = (rowData) => {
        return (
            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                {'MSI-' + (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
            </a>
        );
    };
    const nameTemplate = (rowData) => {

        return (

            rowData?.vendor?.supplierName || 'NA'

        );
    };
    const locationTemplate = (rowData) => {
        return (
            rowData?.vendor?.supplierLocation || 'NA'



        );
    };
    const categoryTemplate = (rowData) => {
        console.log(rowData)
        return (

            categoryList.find(i => i.value === rowData?.vendor?.supplierCategory)?.name || 'Not Found'

        );
    };
    const assessmentDueDate = (rowData) => {
        return (

            DateTime.fromISO(rowData.assessmentEndDate, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy')

        );
    };
    const calibrationStartDate = (rowData) => {
        return (

            rowData.auditorStartDate ? DateTime.fromISO(rowData.auditorStartDate, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy') : 'Not Set'

        );
    };
    const statusTemplate = (rowData) => {

        if (!rowData?.supplierAssignmentSubmission) {
            return <Tag value='Not Started' className='status-tag-orange' />
        } else if (rowData?.supplierAssignmentSubmission?.type !== 1) {
            return <Tag value='InProgress' className='status-tag-blue' />
        } else if (rowData?.supplierAssignmentSubmission?.type === 1) {
            return <Tag value='Completed' className='status-tag-green' />
        }


    }
    const calibrationEndDate = (rowData) => {
        return (

            rowData.auditorEndDate ? DateTime.fromISO(rowData.auditorEndDate, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy') : 'Not Set'


        );
    };
    const contactTemplate = (rowData) => {
        return (

            rowData?.vendor?.supplierContact || 'NA'

        );
    };
    const pySpendBodyTemplate = (rowData) => {
        return `${rowData?.vendor?.supplierSpentOn || '-'} Cr. INR`;
    };
    const RowFilterTemplate = (options, obj) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(data.map((i) => i[obj])))}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const statusOfActionsTemplate = (rowData) => {
        return <span>{rowData.statusOfActions}</span>;
    };
    const team1Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group1?.assessors?.length) {
            return rowData?.group1?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team2Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group2?.assessors?.length) {
            return rowData?.group2?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team3Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group3?.assessors?.length) {
            return rowData?.group3?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team4Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group4?.assessors?.length) {
            return rowData?.group4?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const handleCalibrationClick = (rowData) => {
        // Logic when calibration ID is clicked
        alert('Clicked on: ' + rowData.calibrationId);
    };
    const actionBodytemplate = (rowData) => {
        return (
            <div>
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => editSupplier(rowData)}
                >
                    <i className="pi pi-pencil" />
                </button>
            </div>
        )
    }
    const searchFn = (e) => {
        let val = e.target.value
        setDatas(databk.filter(x => x?.vendor?.supplierName.trim().toLowerCase().includes(val?.trim().toLowerCase()) || x?.vendor?.code.trim().toLowerCase().includes(val?.trim().toLowerCase())))
        setSearch(val)
    }
    return (
        <div>
            <div className="col-12 flex justify-content-end" >
                <div className='col-5'>
                    <span className="p-input-icon-left" style={{ width: '100%' }}>
                        <i className="pi pi-search" />
                        <InputText value={search} style={{ width: '100%' }} onChange={searchFn} placeholder="Search Code/Name" />
                    </span>
                </div>
            </div>
            <DataTable scrollable paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} value={datas} responsiveLayout="scroll" filters={{ cat: { matchMode: 'in', value: null }, stat: { matchMode: 'in', value: null } }} globalFilter={globalFilter} className="mt-2 h-500">
                <Column field="calibrationId" header="Calibration ID" body={calibrationIdBodyTemplate} ></Column>
                <Column field="name" header="Name" body={nameTemplate}></Column>
                <Column field="location" header="Location" body={locationTemplate}></Column>
                <Column field="supplierContact" header="Supplier Contact" body={contactTemplate}></Column>

                <Column field="pySpend" header="PY Spend (Cr. INR)" body={pySpendBodyTemplate} ></Column>
                <Column field="cat" header="Category" body={categoryTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "cat")
                    } ></Column>
                <Column field="selfAssessmentDueDate" header="Self-Assessment Due Date" body={assessmentDueDate} ></Column>
                <Column field="stat" header="Self-Assessment Status" headerStyle={{width:300}} body={statusTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "stat")
                    }  ></Column>
                <Column header="MSI Self-assessment Score" field='supplierAssignmentSubmission.supplierMSIScore'></Column>
                <Column field="calibrationStartDate" header="Calibration Start Date" body={calibrationStartDate}></Column>
                <Column field="calibrationEndDate" header="Calibration End Date" body={calibrationEndDate}></Column>

                <Column field="teamMember1" header="Calibration Team Member 1" body={team1Template}></Column>
                <Column field="teamMember2" header="Calibration Team Member 2" body={team2Template}></Column>
                <Column field="teamMember3" header="Calibration Team Member 3" body={team3Template}></Column>
                <Column field="teamMember3" header="Calibration Team Member 4" body={team4Template}></Column>
                <Column header="Schedule MSI Calibration" body={actionBodytemplate}></Column>


            </DataTable>
        </div>
    );
};

export default SuppliersTable;
