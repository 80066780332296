import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const ESGScoresTab = ({ tabIndex }) => {
  // Data for Tab 2 (Example)
  const tab2Data = [
    {
      category: "Environment",
      avgValue: 15,
      maxValue: 40,
      remainingToMax: 40 - 15,
      achievedColor: "#2C7C69",
      maxColor: "#7FC8A9",
    },
    {
      category: "Social",
      avgValue: 25.75,
      maxValue: 50,
      remainingToMax: 50 - 25.75,
      achievedColor: "#FC6E51",
      maxColor: "#FEB2A8",
    },
    {
      category: "Governance",
      avgValue: 11.5,
      maxValue: 15, // Kept as 15
      remainingToMax: 15 - 11.5,
      achievedColor: "#4A90E2",
      maxColor: "#AFCBFF",
    },
  ];

  // Data for Tab 3 (Example)
  const tab3Data = [
    {
      category: "Environment",
      avgValue: 24,
      maxValue: 40,
      remainingToMax: 40 - 24,
      achievedColor: "#2C7C69",
      maxColor: "#7FC8A9",
    },
    {
      category: "Social",
      avgValue: 26,
      maxValue: 50,
      remainingToMax: 50 - 26,
      achievedColor: "#FC6E51",
      maxColor: "#FEB2A8",
    },
    {
      category: "Governance",
      avgValue: 9.67,
      maxValue: 10,
      remainingToMax: 10 - 9.67,
      achievedColor: "#4A90E2",
      maxColor: "#AFCBFF",
    },
  ];

  const data = tabIndex === 1 ? tab2Data : tab3Data;

  const maxYAxis = Math.max(...data.map((d) => d.maxValue));

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "50%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: "5px", color: "#555" }}>
          ESG Score
        </h3>
        <ResponsiveContainer width="100%" height={360}>
          <BarChart
            data={data}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, maxYAxis]} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Achieved Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Bar
              dataKey="avgValue"
              stackId="score"
              name="Achieved Score"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => (value !== 0 ? value.toFixed(1) : ""),
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={entry.achievedColor}
                />
              ))}
            </Bar>
            <Bar dataKey="remainingToMax" stackId="score" name="Max Score">
              {data.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>
          <div
            className="legend"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <span
                style={{
                  color: "#e3e3e3",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#e3e3e3",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#7FC8A9",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#7FC8A9",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FFB6C1",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FFB6C1",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div style={{ marginRight: "30px" }}>
              <span
                style={{
                  color: "#AFCBFF",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#AFCBFF",
                  marginRight: "5px",
                }}
              ></span>
              Maximum
            </div>
            <div>
              <span
                style={{
                  color: "#b0b0b0",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#b0b0b0",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#2C7C69",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#2C7C69",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FF6F61",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FF6F61",
                  marginRight: "5px",
                }}
              ></span>
            </div>

            <div>
              <span
                style={{
                  color: "#4A90E2",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#4A90E2",
                  marginRight: "5px",
                }}
              ></span>
              Achieved
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ESGScoresTab;
