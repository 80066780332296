import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

const FilterComponent = ({ data, setFilteredData }) => {
  const [filters, setFilters] = useState({
    zone: "All",
    city: "All",
    branch_code: "All",
    area_office: "All",
    start_date_of_calibration: null,
    end_date_of_calibration: null,
  });

  useEffect(() => {
    applyFilters();
  }, [filters]);

  const handleChange = (key, value) => {
    setFilters((prev) => {
      let newFilters = { ...prev, [key]: value };

      if (key === "zone") {
        // Reset city and branch_code when zone is "All"
        if (value === "All") {
          newFilters.city = "All";
          newFilters.branch_code = "All";
        }
      }

      if (key === "city") {
        // Reset branch_code when city is "All"
        if (value === "All") {
          newFilters.branch_code = "All";
        }
      }

      return newFilters;
    });
  };

  const applyFilters = () => {
    let filtered = data;

    if (filters.zone !== "All") {
      filtered = filtered.filter((item) => item.zone === filters.zone);
    }
    if (filters.city !== "All") {
      filtered = filtered.filter((item) => item.city === filters.city);
    }
    if (filters.branch_code !== "All") {
      const selectedBranchCode = filters.branch_code.split("-").pop().trim();
      filtered = filtered.filter(
        (item) => String(item.branch_code).trim() === selectedBranchCode
      );
    }
    if (filters.area_office !== "All") {
      filtered = filtered.filter(
        (item) => item.area_office === filters.area_office
      );
    }
    if (filters.start_date_of_calibration) {
      filtered = filtered.filter(
        (item) =>
          new Date(item.date_of_calibration) >=
          new Date(filters.start_date_of_calibration)
      );
    }
    if (filters.end_date_of_calibration) {
      filtered = filtered.filter(
        (item) =>
          new Date(item.date_of_calibration) <=
          new Date(filters.end_date_of_calibration)
      );
    }

    setFilteredData(filtered);
  };

  const getUniqueOptions = (key, dependencyKey = null) => {
    let filteredData = data;

    if (dependencyKey && filters[dependencyKey] !== "All") {
      filteredData = filteredData.filter(
        (item) => item[dependencyKey] === filters[dependencyKey]
      );
    }

    if (key === "branch_code") {
      return [
        { label: "All", value: "All" },
        ...Array.from(
          new Set(
            filteredData.map(
              (item) => `${item.dealer_name}-${item.branch_code}`
            )
          )
        ).map((value) => ({ label: value, value })),
      ];
    }

    return [
      { label: "All", value: "All" },
      ...Array.from(new Set(filteredData.map((item) => item[key]))).map(
        (value) => ({ label: value, value })
      ),
    ];
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>Zone</label>
        <Dropdown
          value={filters.zone}
          options={getUniqueOptions("zone")}
          onChange={(e) => handleChange("zone", e.value)}
          placeholder="Select Zone"
          className="w-100"
          filter
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>City</label>
        <Dropdown
          value={filters.city}
          options={getUniqueOptions("city", "zone")}
          onChange={(e) => handleChange("city", e.value)}
          placeholder="Select City"
          className="w-100"
          filter
          // disabled={filters.zone === "All"} // Disable if zone is "All"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>Dealer Name and Code</label>
        <Dropdown
          value={filters.branch_code}
          options={getUniqueOptions("branch_code", "city")}
          onChange={(e) => handleChange("branch_code", e.value)}
          placeholder="Select Dealer Code"
          className="w-100"
          filter
          // disabled={filters.city === "All"} // Disable if city is "All"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>Area Office</label>
        <Dropdown
          value={filters.area_office}
          options={getUniqueOptions("area_office")}
          onChange={(e) => handleChange("area_office", e.value)}
          placeholder="Select Area Office"
          className="w-100"
          filter
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>From</label>
        <Calendar
          value={filters.start_date_of_calibration}
          onChange={(e) => handleChange("start_date_of_calibration", e.value)}
          placeholder="Start Date"
          showButtonBar
          showIcon
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>To</label>
        <Calendar
          value={filters.end_date_of_calibration}
          onChange={(e) => handleChange("end_date_of_calibration", e.value)}
          placeholder="End Date"
          showButtonBar
          showIcon
          minDate={filters.start_date_of_calibration}
          disabled={!filters.start_date_of_calibration}
        />
      </div>
    </div>
  );
};

export default FilterComponent;
